import * as React from "react";
import { IoClose } from "react-icons/io5";
import { VscCopy } from "react-icons/vsc";

interface InspectSidebarProps {
  value: string | null;
  handleCloseInspect: () => void;
}

type showMode = 'raw' | 'pretty';

const InspectSidebar: React.FC<InspectSidebarProps> = ({ value, handleCloseInspect }) => {
  const [mode, setMode] = React.useState<showMode>('raw');
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (isValidJson(value)) {
      setMode('pretty');
    } else {
      setMode('raw');
    }
  }, [value]);

  function getPrettyValue(value: string): string | number | readonly string[] {
    try {
      return typeof value === 'object' ? JSON.stringify(value, null, 2) : JSON.stringify(JSON.parse(value), null, 2);
    } catch {
      return typeof value === 'object' ? JSON.stringify(value) : value;
    }
  }

  function getRawValue(value: string | object): string | number | readonly string[] {
    try {
      const val = typeof value === 'object' ? value : JSON.parse(value);
      return   JSON.stringify(JSON.parse(val));
    } catch {
      return typeof value === 'object' ? JSON.stringify(value) : value;
    }
  }

  const onCopy = async () => {
    const el = document.querySelector('#inspect-textarea') as HTMLTextAreaElement;
    try {
      await navigator.clipboard.writeText(el.value);
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  return (
    <>
      <div className="p-5 flex justify-between items-center">
        <h2 className="text-2xl">Inspect</h2>
        <IoClose fontSize={25} className='transition-opacity and duration-200 hover:bg-gray-200 rounded-2xl cursor-pointer p-1' onClick={handleCloseInspect} />
      </div>
      <hr className="w-full text-gray-100" />
      <div className="relative bg-gray-100 mx-4 mb-5 flex-1" style={{ width: 'calc(100%-10px)' }}>
        <textarea readOnly id="inspect-textarea" className="w-full h-full bg-gray-100 p-5 pt-12 text-normal focus:outline-none resize-none" value={mode == 'raw' ? getRawValue(value) : getPrettyValue(value)} />
        <div className="absolute right-1 top-1 flex gap-2 mt-2">
          <div className="flex gap-0 items-center">
            <span
              className={
                'select-none pb-1 pt-[6px] px-3 text-sm cursor-pointer ' +
                (mode === 'raw' ? ' bg-gray-400' : ' bg-gray-300')
              }
              onClick={() => setMode('raw')}
            >
              Raw
            </span>
            <span 
              className={'select-none pb-1 pt-[6px] px-3 text-sm ' +(mode === 'pretty' ? ' bg-gray-400' : ' bg-gray-300')}
              style={{ cursor: isValidJson(value) ? 'pointer' : 'not-allowed' }}
              onClick={() => {
                if (isValidJson(value)) {
                  setMode('pretty');
                }
              }}
            >Pretty
            </span>
          </div>

          {/* Copy Icon and Feedback */}
          <div className="relative">
            <VscCopy
              fontSize={25}
              className="mt-[2px] transition-opacity duration-200 hover:bg-gray-300 cursor-pointer p-1"
              onClick={onCopy}
            />
            {copied && (
              <div className="select-none absolute right-0 top-7 bg-gray-200 text-sm text-gray-700 px-2 py-1 rounded shadow">
                Copied!
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};


function isValidJson(str: string | object) {
  try {
    if (typeof str === 'object') {
      return true;
    }
    const parsed = JSON.parse(str);
    return typeof parsed === 'object' && (str.trim().startsWith('{') || str.trim().startsWith('['));
  } catch {
    return false;
  }
}

export default InspectSidebar;
