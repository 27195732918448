import * as React from "react"
import { User } from 'firebase/auth';
import { useState } from 'react';
import logger from "../../utils/logger";
import { createHeadersForApiCall } from '../../utils/api-utils';
import { ChColumn } from '../schema-contracts';
import { VscCopy, VscDebugConsole, VscDebugRerun, VscDebugStart } from 'react-icons/vsc';
import { VscNewFile } from "react-icons/vsc";
import './ai-query-view.css';

interface AIQueryViewProps {
  user: User;
  columns: ChColumn[];
  onReplaceAndRun: (query: string) => void;
  onReplace: (query: string) => void;
  addGoodAnswer: (prompt: string, answer: string) => void;
  getSelectedQuery: () => string;
}

interface PromptSuggestionProps {
  text: string;
  onClick: (string) => void;
}

const PromptSuggestion: React.FC<PromptSuggestionProps> = ({ text, onClick }) => (
  <span className='bg-[#F4F2F069] mr-6 hover:bg-gray-200 border border-[#747070] rounded-lg block my-1 p-2 cursor-pointer' onClick={() => onClick(text)}>
    {text}
  </span>
);

const InitialPromptSuggestions: React.FC<{ suggestions, onSend: (text: string) => void, suggestionsInProgress }> = ({ suggestions, onSend, suggestionsInProgress }) => {
  return (
    <>
      { suggestionsInProgress ? <div> <span className="pb-4 text-gray-500">Getting suggestions...</span> 
                                      <span className='spinner ml-3 mt-2 w-[10px] h-[10px] inline-block font-[26px] text-gray-900' />  </div>
      : suggestions.map((suggestion, index) => <PromptSuggestion key={"initPrompt" + index} text={suggestion} onClick={(text) => onSend(text)} />)}

    </>
  )
};

// const AfterQueryShortcutSuggestions: React.FC<{ onSend: (text: string) => void }> = ({ onSend }) => (
//   <>
//     <PromptSuggestion text="Enter 'rr' to insert suggestion and run " onClick={() => onSend('rr')} />
//     <PromptSuggestion text="Enter 'r' to insert suggestion without running" onClick={() => onSend('r')} />
//     <PromptSuggestion text="Enter 'c' to copy suggestion to clipboard " onClick={() => onSend('c')} />
//   </>
// );

const getRandomSuggestions = async (user: User) => {
  const reallyLocalhost = window.location.hostname === 'localhost';
  const queryApiUrl = reallyLocalhost ? 'http://localhost:8000/api/ai/get-questions' : 'https://obics.io/api/ai/get-questions/';
  const response = await fetch(queryApiUrl, {
    method: 'GET',
    headers: await createHeadersForApiCall(user, logger),
  });
  return (await response.json()) as string[];
}

interface IMessage {
  role: "assistant" | "user";
  content: string;
  isQuery: boolean;
}

let gotRandomSuggestions = false;

const AIQueryView = ({ user, columns, onReplace, onReplaceAndRun, addGoodAnswer, getSelectedQuery }: AIQueryViewProps) => {

  const fixMessage: IMessage = { role: "assistant", content: "How can I improve this query?", isQuery: false };
  const initialMessage: IMessage = { role: "assistant", content: "Hello! How can I help you today?", isQuery: false };
  const [messages, setMessages] = useState<IMessage[]>([
    initialMessage,
  ]);
  const [userInput, setUserInput] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsInProgress, setSuggestionsInProgress] = useState(false);

  React.useEffect(() => {
    if (!gotRandomSuggestions) {
      setSuggestionsInProgress(true);
      gotRandomSuggestions = true;
      getRandomSuggestions(user).then((suggestions1) => {
        setSuggestions(suggestions1);
        setSuggestionsInProgress(false);
    }).catch((error) => {
        logger.error('Error fetching suggestions: ' + (error && typeof error.toString === 'function' && error.toString()), 'Id1F5');
        setSuggestionsInProgress(false);
      });
    }
  }, []);


  const handleSend = async (overrideUserInput: string = undefined) => {
    let input = overrideUserInput || userInput;
    console.log('handleSend called. input=' + input);
    input = input.trim();
    if (input === "") return;

    const messagesNew: IMessage[] = [...messages, { role: "user", content: input, isQuery: false }];
    setMessages(messagesNew);
    logger.info(JSON.stringify({ eventName: "AIPrmopt", prompt: input }), 'j0vhd');
    setUserInput("");

    
    try {
      
      if (input.toLowerCase() == "ga" || input.toLowerCase() == "ga:") {
        const question = (messages.filter(x => !x.isQuery && x.role == "user" && x.content.length > 3).slice(-1)[0])?.content; // last query
        const answer = getSelectedQuery();
        addGoodAnswer(question, answer);
        return;
      }

      setInProgress(true);
      scrollToBottom();
      const isLocalhost = window.location.hostname === 'localhost'; // UNCOMMENT DURING DEBUG IF NEEDED 
      const url = isLocalhost ? 'http://localhost:8000/api/ai-query-prompt' : 'https://obics.io/api/ai-query-prompt/';
      const headers = await createHeadersForApiCall(user, logger);

      const messagesForRequest = [...messagesNew];
      messagesForRequest.splice(0, 1);
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          // remove the first item of "Hello! How can I help you today?"
          prompts: messagesForRequest,
          columns
        }),
        headers,
      });
      const jsonResponse = await res.json();
      let actualResponse = jsonResponse.response as string;

      const isQuery = actualResponse.startsWith("```");
      actualResponse = isQuery ? actualResponse.substring(3, actualResponse.length - 3) : actualResponse;
      actualResponse = actualResponse.startsWith("sql") ? actualResponse.substring(3) : actualResponse;
      actualResponse = actualResponse.trim();

      console.log('after response, messagesNew = ', messagesNew);
      setMessages([
        ...messagesNew,
        { role: "assistant", content: actualResponse, isQuery },
      ]);
      setTimeout(() => {
        document.getElementById('run-assistant-button').focus();
      }, 150);

    } catch (error) {
      setMessages([
        ...messagesNew,
        { role: "assistant", content: "Sorry, I'm unable to process your request. Error: " + error?.toString(), isQuery: false },
      ]);
    } finally {
      setInProgress(false);
      // scroll message container to bottom
      scrollToBottom();
    }


  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSend();
      event.preventDefault();
    }
  }

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(event.target.value);
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';
  };

  const shouldShowActionItemsAfterAnswer = () => messages.length > 1 && messages[messages.length - 1].isQuery;
  const shouldShowInitialSuggestions = () => messages[messages.length - 1].content === initialMessage.content;



  function newChat() {
    setMessages([initialMessage]);
    focusPromptInput();
  }

  function copyLastQuery() {
    const lastQueryText = (messages.filter(x => x.isQuery).slice(-1)[0])?.content; // last query
    if (lastQueryText) {
      navigator.clipboard.writeText(lastQueryText)
    }
  }

  function fixQuery() {
    setMessages([...messages, fixMessage]);
    focusPromptInput();
  }

  function replaceAndRun() {
    const lastQueryText = (messages.filter(x => x.isQuery).slice(-1)[0])?.content; // last query
    if (lastQueryText) {
      onReplaceAndRun(lastQueryText);
    }
  }


  const handleKeyUpInAssistant = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'n') {
      newChat();
      event.preventDefault();
      return;
    }

    if (!shouldShowActionItemsAfterAnswer()) return;

    if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'c') {
      copyLastQuery();
      event.preventDefault();
    } else if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'f') {
      fixQuery();
      event.preventDefault();
    } else if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'r') {
      replaceAndRun();
      event.preventDefault();
    } else if (event.key === 'ArrowRight') {
      const focusableElements = document.querySelectorAll('.ai-query-action-button');
      const index = Array.prototype.indexOf.call(focusableElements, document.activeElement);
      if (index > -1 && index < focusableElements.length - 1) {
        (focusableElements[index + 1] as HTMLElement).focus();
      }
      event.preventDefault();
    } else if (event.key === 'ArrowLeft') {
      const focusableElements = document.querySelectorAll('.ai-query-action-button');
      const index = Array.prototype.indexOf.call(focusableElements, document.activeElement);
      if (index > 0 && index <= focusableElements.length - 1) {
        (focusableElements[index - 1] as HTMLElement).focus();
      }
      event.preventDefault();
    }
  }


  function focusPromptInput() {
    setTimeout(() => {
      const promptInput = document.getElementById("prompt-input") as HTMLTextAreaElement;
      promptInput.focus();
    }, 150);
  }


  return (
    <div className="flex flex-col h-full bg-transparent " onKeyUp={handleKeyUpInAssistant}>

      {/* Messages Container */}
      <div id="message-container" className="flex-1 overflow-y-auto border border-[#E8E8E8] rounded-2xl p-4 space-y-4 ">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${msg.role === "user" ? "justify-end" : "justify-start"
              }`}
          >
            <div className={`max-w-xs px-4 py-2 ${msg.role === "user"
              ? "bg-bgdark1 text-white rounded-t-xl rounded-bl-xl"
              : "bg-[#F4F2F085] text-black rounded-t-xl rounded-br-xl"
              }`}>
              {msg.content}
            </div>

          </div>
        ))}
        {inProgress && <div className='spinner2' />}
        {shouldShowActionItemsAfterAnswer() &&
          <div className='flex gap-3'>
            <button id="run-assistant-button" className='ai-query-action-button ' title='Run (Ctrl + Alt + R)'
              onClick={replaceAndRun}>
              <VscDebugStart
                fontSize={35}
                className="mt-[2px] cursor-pointer p-1"
              // onClick={onCopy}
              />
            </button>

            <button className='ai-query-action-button' title='New Query (Ctrl + Alt + N)'
              onClick={newChat}>
              <VscNewFile
                fontSize={35}
                className="mt-[2px] cursor-pointer p-1" />
            </button>

            <button className='ai-query-action-button' title='Copy to clipboard (Ctrl + Alt + C)'
              onClick={copyLastQuery}>
              <VscCopy
                fontSize={35}
                className="mt-[2px] cursor-pointer p-1"
              // onClick={onCopy}
              />
            </button>

            <button className='ai-query-action-button ' title='Fix query (Ctrl + Alt + F)'
              onClick={fixQuery}>
              <VscDebugConsole
                fontSize={35}
                className="mt-[2px] cursor-pointer p-1"
              // onClick={onCopy}
              />
            </button>

            {/* <button className='ai-query-action-button ' title='Try again with a different model (Ctrl + Alt + T)'>
              <VscDebugRerun
                fontSize={35}
                className="mt-[2px] cursor-pointer p-1"
              // onClick={onCopy}
              />
            </button> */}



          </div>
        }
      </div>

      <div id="prompt-suggestions" className='mt-4 mb-2'>
        {
          shouldShowInitialSuggestions() && <InitialPromptSuggestions onSend={handleSend} suggestions={suggestions} suggestionsInProgress= {suggestionsInProgress} />
        }
      </div>

      {/* Input Area */}
      {!shouldShowActionItemsAfterAnswer() && !inProgress && <div className="mt-4 flex items-center gap-2 border border-gray-300 rounded-lg py-[2px] px-1">
        <textarea
          id="prompt-input"
          className="flex-1 px-4 shadow-sm focus:outline-none resize-none " // Adjust the height as needed
          placeholder="Type your message..."
          value={userInput}
          onChange={handleInput}
          onKeyUp={handleKeyUp}
          rows={1} // Specifies the number of rows
        ></textarea>
        <button
          onClick={() => handleSend()}
          className="w-[40px] h-[40px] bg-text1 text-white rounded-lg shadow hover:bg-black transition flex items-center justify-center"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5407 4.96418L5.54741 2.46752C2.19324 0.787517 0.816576 2.16418 2.49658 5.51835L3.00408 6.53335C3.14991 6.83085 3.14991 7.17502 3.00408 7.47252L2.49658 8.48168C0.816576 11.8359 2.18741 13.2125 5.54741 11.5325L10.5407 9.03585C12.7807 7.91585 12.7807 6.08418 10.5407 4.96418ZM8.65658 7.43752H5.50658C5.26741 7.43752 5.06908 7.23918 5.06908 7.00002C5.06908 6.76085 5.26741 6.56252 5.50658 6.56252H8.65658C8.89574 6.56252 9.09408 6.76085 9.09408 7.00002C9.09408 7.23918 8.89574 7.43752 8.65658 7.43752Z"
              fill="white"
            />
          </svg>
        </button>
      </div>}

    </div>
  );
};

export default AIQueryView;


function scrollToBottom() {
  setTimeout(() => {
    const messageContainer = document.getElementById("message-container");
    messageContainer.scrollTop = messageContainer.scrollHeight;

    // const promptInput = document.getElementById("prompt-input");
    // promptInput.focus();
  }, 100);
}

