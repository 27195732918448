import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';

const intervals = [
  { label: 'None', value: null },
  { label: '5s', value: 5 },
  { label: '10s', value: 10 },
  { label: '30s', value: 30 },
  { label: '1m', value: 60 },
  { label: '5m', value: 300 },
];

interface AutoRefreshButtonProps {
  onIntervalSelected: (interval: number) => void;
}

const AutoRefreshButton = forwardRef((props: AutoRefreshButtonProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const dropdownRef = useRef(null);

  useImperativeHandle(ref, () => ({
    callStopAutoRefresh: stopAutoRefresh,
    callEnable: () => setIsEnabled(true),
    callDisable: () => setIsEnabled(false),
    callSetInterval: (interval) => handleSelectInterval(intervals.find((i) => i.value === interval)),
  }));

  const stopAutoRefresh = () => {
    setSelectedInterval(null);
    setIsOpen(false);
  }

  // Close the dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectInterval = (interval) => {
    setSelectedInterval(interval);
    setIsOpen(false);
    props.onIntervalSelected(interval.value);
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      {/* Button with the refresh icon */}
      <button
        disabled={!isEnabled}
        type="button"
        onClick={handleToggleDropdown}
        title="Auto-refresh"
        className={"relative flex items-center rounded-xl border border-gray-300 bg-white px-3 py-2  focus:outline-none" + (isEnabled ? " hover:bg-gray-50" : " cursor-not-allowed")}
      >
        <FiRefreshCcw className={isEnabled ? "text-gray-800" : "text-gray-300"} size={20} />
        {/* If there is a selected interval, show the overlay */}
        {selectedInterval && selectedInterval.value != null && (
          <span
            className=" absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 rounded-sm  px-1  text-xs  font-bold  text-white bg-orange1 text-gray-800"
          >
            {intervals.find((i) => i.value === selectedInterval.value)?.label}
          </span>
        )}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <ul
          className=" absolute left-0  top-full z-10 mt-2 list-none rounded-xl border border-gray-300 bg-white shadow-md"
        >
          {intervals.map((interval) => (
            <li key={interval.value}>
              <button
                type="button"
                onClick={() => handleSelectInterval(interval)}
                className=" w-full py-2 px-4 text-left text-gray-700 hover:bg-gray-100 focus:outline-none">
                {interval.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default AutoRefreshButton;
