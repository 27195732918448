import * as monaco from 'monaco-editor';

export const getAdjacentLines = (lines: string[], position: monaco.IPosition, removeComments: boolean): string => {
  const lineNumber = position.lineNumber - 1; // Monaco Editor line numbers are 1-based

  let startLine = 0;
  let endLine = lines.length - 1;

  // Traverse upwards to find the blank line
  for (let i = lineNumber - 1; i >= 0; i--) {
    if (lines[i].trim() === '') {
      startLine = i + 1;
      break;
    }
  }

  // Traverse downwards to find the blank line
  for (let i = lineNumber + 1; i < lines.length; i++) {
    if (lines[i].trim() === '') {
      endLine = i - 1;
      break;
    }
  }

  let adjacentLines = lines.slice(startLine, endLine + 1);
  if (removeComments) {
    adjacentLines = removeClickhouseComments(adjacentLines);
  }
  
  return adjacentLines.join('\n');
};

export function removeClickhouseComments(sqlLines: string[]): string[] {
  let inBlockComment = false;
  const result: string[] = [];

  for (const line of sqlLines) {
    let cleanedLine = "";
    let i = 0;

    while (i < line.length) {
      // If we're *not* already in a block comment, check for start of one
      if (!inBlockComment && i + 1 < line.length) {
        // Check for single-line comment: --
        if (line[i] === "-" && line[i + 1] === "-") {
          // Everything from here to the end of the line is a comment
          break;
        }
        // Check for block comment start: /*
        if (line[i] === "/" && line[i + 1] === "*") {
          inBlockComment = true;
          i += 2; // Skip past '/*'
          continue;
        }
      }

      // If we're currently in a block comment, check for its end: */
      if (inBlockComment && i + 1 < line.length) {
        if (line[i] === "*" && line[i + 1] === "/") {
          inBlockComment = false;
          i += 2; // Skip past '*/'
          continue;
        }
        // Otherwise, ignore characters inside the block comment
        i++;
        continue;
      }

      // If we're not in a block comment and not at a single-line comment,
      // this character is part of the valid SQL
      if (!inBlockComment) {
        cleanedLine += line[i];
      }

      i++;
    }

    // Trim trailing spaces; optionally skip empty lines if needed
    result.push(cleanedLine.trim());
  }

  return result;
}