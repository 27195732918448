export function findLastIndex(arr, predicate) {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i, arr)) {
      return i;
    }
  }
  return -1;
}

export function isValidDate(input): boolean {
  // Must be a string to be considered valid
  if (typeof input !== 'string') {
      return false;
  }

  // Attempt to parse the date string
  const date = new Date(input);

  // If the time value is NaN, it's not a valid date
  return !isNaN(date.getTime());
}