const baseFunctions = {
  regularFunctions: [
    // initial
    { "label": "AS", "documentation": "" },
    { "label": "if", "documentation": "Performs conditional branching; returns 'then' if condition is true, else returns 'else'." },
    { "label": "multiIf", "documentation": "Allows writing multiple conditional branches compactly." },
    { "label": "clamp", "documentation": "Constraints a value to a specified range." },
    
    // date time functions
    { "label": "toDayOfMonth", "documentation": "Returns the day of the month (1-31) of a date or date with time." },
    { "label": "toDayOfWeek", "documentation": "Returns the number of the day within the week of a date or date with time." },
    { "label": "now", "documentation": "Returns the current date and time." },
    { "label": "today", "documentation": "Returns the current date." },
    { "label": "yesterday", "documentation": "Returns the date of the previous day." },
    { "label": "INTERVAL", "documentation": "" },
    { "label": "DAY", "documentation": "" },
    { "label": "WEEK", "documentation": "" },
    { "label": "MONTH", "documentation": "" },
    { "label": "HOUR", "documentation": "" },
    { "label": "MINUTE", "documentation": "" },
    { "label": "SECOND", "documentation": "" },
    { "label": "addDays", "documentation": "Adds a specified number of days to a date." },
    { "label": "subtractDays", "documentation": "Subtracts a specified number of days from a date." },
    { "label": "toStartOfISOYear", "documentation": "Rounds a date or date-time to the start of the ISO year." },
    { "label": "toStartOfDay", "documentation": "Rounds a date or date-time to the start of the day." },
    { "label": "toStartOfWeek", "documentation": "Rounds a date or date-time to the start of the week." },
    { "label": "toStartOfMonth", "documentation": "Rounds a date or date-time to the start of the month." },
    { "label": "toStartOfQuarter", "documentation": "Rounds a date or date-time to the start of the quarter." },
    { "label": "toStartOfYear", "documentation": "Rounds a date or date-time to the start of the year." },
    { "label": "toStartOfMinute", "documentation": "Rounds a date-time to the start of the minute." },
    { "label": "toStartOfFiveMinute", "documentation": "Rounds a date-time to the start of the five-minute interval." },
    { "label": "toStartOfHour", "documentation": "Rounds a date-time to the start of the hour." },
    { "label": "toStartOfTenMinutes", "documentation": "Rounds a date-time to the start of the ten-minute interval." },
    { "label": "toStartOfFifteenMinutes", "documentation": "Rounds a date-time to the start of the fifteen-minute interval." },
    { "label": "toStartOfInterval", "documentation": "Rounds a date-time to the start of a specified interval." },
    { "label": "toMonday", "documentation": "Returns the Monday of the week for a given date." },
    { "label": "toISOYear", "documentation": "Returns the ISO year number for a given date." },
    { "label": "toISOWeek", "documentation": "Returns the ISO week number for a given date." },
    { "label": "toISOYearWeek", "documentation": "Returns the ISO year and week number for a given date." },
    { "label": "toDayOfYear", "documentation": "Returns the day number within the year for a given date." },
    { "label": "toDayOfMonth", "documentation": "Returns the day number within the month for a given date." },
    { "label": "toDayOfWeek", "documentation": "Returns the day number within the week for a given date." },
    { "label": "toHour", "documentation": "Returns the hour value for a given date-time." },
    { "label": "toMinute", "documentation": "Returns the minute value for a given date-time." },
    { "label": "toSecond", "documentation": "Returns the second value for a given date-time." },
    { "label": "toUnixTimestamp", "documentation": "Converts a date-time to a Unix timestamp." },
    { "label": "fromUnixTimestamp", "documentation": "Converts a Unix timestamp to a date-time." },
    { "label": "formatDateTime", "documentation": "Formats a date-time according to the specified pattern." },
    { "label": "parseDateTime", "documentation": "Parses a string into a date-time according to the specified format." },
    { "label": "dateDiff", "documentation": "Calculates the difference between two dates in specified units." },
    { "label": "dateAdd", "documentation": "Adds a specified interval to a date." },
    { "label": "dateSub", "documentation": "Subtracts a specified interval from a date." },
    { "label": "toYYYYMM", "documentation": "Converts a date to the 'YYYYMM' format." },
    { "label": "toYYYYMMDD", "documentation": "Converts a date to the 'YYYYMMDD' format." },
    { "label": "toYYYYMMDDhhmmss", "documentation": "Converts a date-time to the 'YYYYMMDDhhmmss' format." },
    { "label": "toQuarter", "documentation": "Returns the quarter number for a given date." },
    { "label": "toMonth", "documentation": "Returns the month number for a given date." },
    { "label": "toYear", "documentation": "Returns the year number for a given date." },
    { "label": "toWeek", "documentation": "Returns the week number for a given date." },
    
    // array functions
    { "label": "empty", "documentation": "Checks if the input array is empty." },
    { "label": "notEmpty", "documentation": "Checks if the input array is not empty." },
    { "label": "length", "documentation": "Returns the length of the input array." },
    { "label": "emptyArrayUInt8", "documentation": "Returns an empty array of UInt8 type." },
    { "label": "emptyArrayUInt16", "documentation": "Returns an empty array of UInt16 type." },
    { "label": "emptyArrayUInt32", "documentation": "Returns an empty array of UInt32 type." },
    { "label": "emptyArrayUInt64", "documentation": "Returns an empty array of UInt64 type." },
    { "label": "emptyArrayInt8", "documentation": "Returns an empty array of Int8 type." },
    { "label": "emptyArrayInt16", "documentation": "Returns an empty array of Int16 type." },
    { "label": "emptyArrayInt32", "documentation": "Returns an empty array of Int32 type." },
    { "label": "emptyArrayInt64", "documentation": "Returns an empty array of Int64 type." },
    { "label": "emptyArrayFloat32", "documentation": "Returns an empty array of Float32 type." },
    { "label": "emptyArrayFloat64", "documentation": "Returns an empty array of Float64 type." },
    { "label": "emptyArrayDate", "documentation": "Returns an empty array of Date type." },
    { "label": "emptyArrayDateTime", "documentation": "Returns an empty array of DateTime type." },
    { "label": "emptyArrayString", "documentation": "Returns an empty array of String type." },
    { "label": "emptyArrayToSingle", "documentation": "Creates an array containing a single default value of the specified type." },
    { "label": "range", "documentation": "Creates an array of numbers from start to end (exclusive) with an optional step." },
    { "label": "array", "documentation": "Creates an array from the specified arguments." },
    { "label": "arrayWithConstant", "documentation": "Creates an array filled with a constant value." },
    { "label": "arrayConcat", "documentation": "Concatenates multiple arrays." },
    { "label": "arrayElement", "documentation": "Returns the element at a specified index from the array." },
    { "label": "arrayElementOrNull", "documentation": "Returns the element at a specified index or null if out of bounds." },
    { "label": "has", "documentation": "Checks if the array contains a specified element." },
    { "label": "hasAll", "documentation": "Checks if the array contains all specified elements." },
    { "label": "hasAny", "documentation": "Checks if the array contains any of the specified elements." },
    { "label": "hasSubstr", "documentation": "Checks if the array contains a specified substring." },
    { "label": "indexOf", "documentation": "Returns the index of the first occurrence of an element in the array." },
    { "label": "indexOfAssumeSorted", "documentation": "Returns the index of an element in a pre-sorted array." },
    { "label": "arrayCount", "documentation": "Counts the number of elements matching a condition in the array." },
    { "label": "arrayDotProduct", "documentation": "Calculates the dot product of two arrays." },
    { "label": "countEqual", "documentation": "Counts the occurrences of a value in an array." },
    { "label": "arrayEnumerate", "documentation": "Returns an array of indices for the elements in the array." },
    { "label": "arrayEnumerateUniq", "documentation": "Enumerates unique elements in an array." },
    { "label": "arrayPopBack", "documentation": "Removes the last element from the array." },
    { "label": "arrayPopFront", "documentation": "Removes the first element from the array." },
    { "label": "arrayPushBack", "documentation": "Adds an element to the end of the array." },
    { "label": "arrayPushFront", "documentation": "Adds an element to the start of the array." },
    { "label": "arrayResize", "documentation": "Resizes the array to the specified length." },
    { "label": "arraySlice", "documentation": "Returns a slice of the array." },
    { "label": "arraySort", "documentation": "Sorts the elements of the array." },
    { "label": "arrayReverse", "documentation": "Reverses the elements of the array." },
    { "label": "arrayUniq", "documentation": "Returns an array with unique elements." },
    { "label": "arrayJoin", "documentation": "Converts an array into multiple rows in a query." },
    { "label": "arrayDistinct", "documentation": "Removes duplicate elements from the array." },
    { "label": "arrayFlatten", "documentation": "Flattens a nested array." },
    { "label": "arrayZip", "documentation": "Combines multiple arrays into an array of tuples." },
    { "label": "arrayMap", "documentation": "Applies a function to each element in the array." },
    { "label": "arrayFilter", "documentation": "Filters elements in the array based on a condition." },
    { "label": "arrayExists", "documentation": "Checks if any element in the array matches a condition." },
    { "label": "arrayAll", "documentation": "Checks if all elements in the array match a condition." },
    { "label": "arraySum", "documentation": "Calculates the sum of the elements in the array." },
    { "label": "arrayMin", "documentation": "Finds the minimum value in the array." },
    { "label": "arrayMax", "documentation": "Finds the maximum value in the array." },
    { "label": "arrayAvg", "documentation": "Calculates the average of the elements in the array." },
    { "label": "arrayCumSum", "documentation": "Calculates the cumulative sum of the elements in the array." },
    { "label": "arrayProduct", "documentation": "Calculates the product of the elements in the array." },
    { "label": "arrayShuffle", "documentation": "Randomly shuffles the elements of the array." },
    // arithmetic

    { "label": "plus", "documentation": "Adds two numbers." },
    { "label": "minus", "documentation": "Subtracts the second number from the first." },
    { "label": "multiply", "documentation": "Multiplies two numbers." },
    { "label": "divide", "documentation": "Divides the first number by the second." },
    { "label": "intDiv", "documentation": "Performs integer division (truncates the result to an integer)." },
    { "label": "intDivOrZero", "documentation": "Performs integer division and returns 0 if the divisor is 0." },
    { "label": "isFinite", "documentation": "Checks if a number is finite." },
    { "label": "isInfinite", "documentation": "Checks if a number is infinite." },
    { "label": "ifNotFinite", "documentation": "Returns a default value if the input is not finite; otherwise, returns the input." },
    { "label": "isNaN", "documentation": "Checks if a value is NaN (not a number)." },
    { "label": "modulo", "documentation": "Calculates the remainder of the division of two numbers." },
    { "label": "moduloOrZero", "documentation": "Calculates the remainder of the division and returns 0 if the divisor is 0." },
    { "label": "positiveModulo", "documentation": "Returns a positive remainder when dividing two numbers." },
    { "label": "negate", "documentation": "Returns the additive inverse of a number." },
    { "label": "abs", "documentation": "Returns the absolute value of a number." },

    // bit functions
    { "label": "bitAnd", "documentation": "Performs a bitwise AND operation between two numbers." },
    { "label": "bitOr", "documentation": "Performs a bitwise OR operation between two numbers." },
    { "label": "bitXor", "documentation": "Performs a bitwise XOR operation between two numbers." },
    { "label": "bitNot", "documentation": "Performs a bitwise NOT operation on a number." },
    { "label": "bitShiftLeft", "documentation": "Shifts the bits of a number to the left by a specified number of positions." },
    { "label": "bitShiftRight", "documentation": "Shifts the bits of a number to the right by a specified number of positions." },
    { "label": "bitRotateLeft", "documentation": "Rotates the bits of a number to the left by a specified number of positions." },
    { "label": "bitRotateRight", "documentation": "Rotates the bits of a number to the right by a specified number of positions." },
    { "label": "bitSlice", "documentation": "Extracts a subset of bits from a number starting at a specified offset and for a specified length." },
    { "label": "byteSlice", "documentation": "Extracts a subset of bytes from a number starting at a specified offset and for a specified length." },
    { "label": "bitTest", "documentation": "Tests whether a specific bit is set in a number." },
    { "label": "bitTestAll", "documentation": "Checks if all specified bits are set in a number." },
    { "label": "bitTestAny", "documentation": "Checks if any of the specified bits are set in a number." },
    { "label": "bitCount", "documentation": "Counts the number of set bits (1s) in a number." },
    { "label": "bitHammingDistance", "documentation": "Calculates the Hamming distance between two numbers (number of differing bits)." },
    // bitmap functions

    { "label": "bitmapBuild", "documentation": "Creates a bitmap from a set of integers." },
    { "label": "bitmapToArray", "documentation": "Converts a bitmap into an array of integers." },
    { "label": "bitmapSubsetInRange", "documentation": "Extracts a subset of the bitmap within a specified range of values." },
    { "label": "bitmapSubsetLimit", "documentation": "Extracts a subset of the bitmap limited to a specified number of values." },
    { "label": "subBitmap", "documentation": "Returns a sub-bitmap starting at a specific offset and with a specific length." },
    { "label": "bitmapContains", "documentation": "Checks if a bitmap contains a specific value." },
    { "label": "bitmapHasAny", "documentation": "Checks if a bitmap contains any of the values in a specified set." },
    { "label": "bitmapHasAll", "documentation": "Checks if a bitmap contains all the values in a specified set." },
    { "label": "bitmapCardinality", "documentation": "Returns the number of elements in a bitmap." },
    { "label": "bitmapMin", "documentation": "Returns the smallest value in a bitmap." },
    { "label": "bitmapMax", "documentation": "Returns the largest value in a bitmap." },
    { "label": "bitmapTransform", "documentation": "Applies a transformation function to the elements of a bitmap." },
    { "label": "bitmapAnd", "documentation": "Performs a bitwise AND operation on two bitmaps." },
    { "label": "bitmapOr", "documentation": "Performs a bitwise OR operation on two bitmaps." },
    { "label": "bitmapXor", "documentation": "Performs a bitwise XOR operation on two bitmaps." },
    { "label": "bitmapAndnot", "documentation": "Performs a bitwise AND NOT operation on two bitmaps." },
    { "label": "bitmapAndCardinality", "documentation": "Calculates the cardinality of the result of a bitwise AND operation on two bitmaps." },
    { "label": "bitmapOrCardinality", "documentation": "Calculates the cardinality of the result of a bitwise OR operation on two bitmaps." },
    { "label": "bitmapXorCardinality", "documentation": "Calculates the cardinality of the result of a bitwise XOR operation on two bitmaps." },
    { "label": "bitmapAndnotCardinality", "documentation": "Calculates the cardinality of the result of a bitwise AND NOT operation on two bitmaps." },

    //dictionary functions

    { "label": "dictGet", "documentation": "Retrieves a value from a dictionary by key." },
    { "label": "dictGetOrDefault", "documentation": "Retrieves a value from a dictionary by key, or returns a default value if the key is not found." },
    { "label": "dictGetOrNull", "documentation": "Retrieves a value from a dictionary by key, or returns NULL if the key is not found." },
    { "label": "dictHas", "documentation": "Checks if a dictionary contains a specific key." },
    { "label": "dictGetHierarchy", "documentation": "Retrieves the hierarchy of keys from a dictionary, starting from a specific key." },
    { "label": "dictIsIn", "documentation": "Checks if a key belongs to a specific hierarchy in the dictionary." },
    { "label": "dictGetChildren", "documentation": "Retrieves the children of a specific key in the dictionary." },
    { "label": "dictGetDescendant", "documentation": "Retrieves all descendants of a specific key in the dictionary." },
    { "label": "dictGetAll", "documentation": "Retrieves all keys and values from the dictionary." },

    // distance

    { "label": "L1Norm", "documentation": "Calculates the L1 norm (sum of absolute values) of a vector." },
    { "label": "L2Norm", "documentation": "Calculates the L2 norm (Euclidean norm) of a vector." },
    { "label": "L2SquaredNorm", "documentation": "Calculates the squared L2 norm of a vector." },
    { "label": "LinfNorm", "documentation": "Calculates the Linf norm (maximum absolute value) of a vector." },
    { "label": "LpNorm", "documentation": "Calculates the Lp norm of a vector for a specified p value." },
    { "label": "L1Distance", "documentation": "Calculates the L1 distance (Manhattan distance) between two vectors." },
    { "label": "L2Distance", "documentation": "Calculates the L2 distance (Euclidean distance) between two vectors." },
    { "label": "L2SquaredDistance", "documentation": "Calculates the squared L2 distance between two vectors." },
    { "label": "LinfDistance", "documentation": "Calculates the Linf distance (maximum absolute difference) between two vectors." },
    { "label": "LpDistance", "documentation": "Calculates the Lp distance between two vectors for a specified p value." },
    { "label": "L1Normalize", "documentation": "Normalizes a vector using the L1 norm." },
    { "label": "L2Normalize", "documentation": "Normalizes a vector using the L2 norm." },
    { "label": "LinfNormalize", "documentation": "Normalizes a vector using the Linf norm." },
    { "label": "LpNormalize", "documentation": "Normalizes a vector using the Lp norm for a specified p value." },
    { "label": "cosineDistance", "documentation": "Calculates the cosine distance between two vectors." },


    //TODO: Geo functions with all subset https://clickhouse.com/docs/en/sql-reference/functions/geo/coordinates

    // encoding functions 
    { "label": "hex", "documentation": "Encodes binary data or integers into a hexadecimal string." },
    { "label": "unhex", "documentation": "Decodes a hexadecimal string back into binary data." },
    { "label": "bin", "documentation": "Encodes an integer into a binary string." },
    { "label": "unbin", "documentation": "Decodes a binary string back into an integer." },
    { "label": "bitmaskToList", "documentation": "Converts a bitmask into a list of positions of set bits." },
    { "label": "bitmaskToArray", "documentation": "Converts a bitmask into an array of positions of set bits." },
    { "label": "bitPositionsToArray", "documentation": "Returns an array of positions of bits set to 1 in the binary representation of a number." },
    { "label": "mortonEncode", "documentation": "Encodes coordinates into a Morton code (Z-order curve)." },
    { "label": "mortonDecode", "documentation": "Decodes a Morton code into coordinates." },
    { "label": "hilbertEncode", "documentation": "Encodes coordinates into a Hilbert curve index." },
    { "label": "hilbertDecode", "documentation": "Decodes a Hilbert curve index into coordinates." },


    //encryption functions

    { "label": "encrypt", "documentation": "Encrypts data using a specified encryption algorithm and key." },
    { "label": "aes_encrypt_mysql", "documentation": "Encrypts data using MySQL-compatible AES encryption." },
    { "label": "decrypt", "documentation": "Decrypts data that was previously encrypted with the 'encrypt' function." },
    { "label": "tryDecrypt", "documentation": "Attempts to decrypt data and returns NULL if decryption fails." },
    { "label": "aes_decrypt_mysql", "documentation": "Decrypts data that was encrypted using MySQL-compatible AES encryption." },

    // hash
    { "label": "halfMD5", "documentation": "Computes the first half of an MD5 hash." },
    { "label": "MD4", "documentation": "Computes the MD4 hash of the input." },
    { "label": "MD5", "documentation": "Computes the MD5 hash of the input." },
    { "label": "RIPEMD160", "documentation": "Computes the RIPEMD160 hash of the input." },
    { "label": "sipHash64", "documentation": "Computes a 64-bit SipHash of the input." },
    { "label": "sipHash64Keyed", "documentation": "Computes a keyed 64-bit SipHash of the input." },
    { "label": "sipHash128", "documentation": "Computes a 128-bit SipHash of the input." },
    { "label": "sipHash128Keyed", "documentation": "Computes a keyed 128-bit SipHash of the input." },
    { "label": "sipHash128Reference", "documentation": "Computes a 128-bit reference SipHash." },
    { "label": "sipHash128ReferenceKeyed", "documentation": "Computes a keyed 128-bit reference SipHash." },
    { "label": "cityHash64", "documentation": "Computes a 64-bit CityHash of the input." },
    { "label": "intHash32", "documentation": "Computes a 32-bit hash of an integer." },
    { "label": "intHash64", "documentation": "Computes a 64-bit hash of an integer." },
    { "label": "SHA1", "documentation": "Computes the SHA1 hash of the input." },
    { "label": "SHA224", "documentation": "Computes the SHA224 hash of the input." },
    { "label": "SHA256", "documentation": "Computes the SHA256 hash of the input." },
    { "label": "SHA512", "documentation": "Computes the SHA512 hash of the input." },
    { "label": "SHA512_256", "documentation": "Computes the SHA512/256 hash of the input." },
    { "label": "BLAKE3", "documentation": "Computes the BLAKE3 hash of the input." },
    { "label": "URLHash", "documentation": "Computes a hash of a URL, optionally with a maximum length." },
    { "label": "farmFingerprint64", "documentation": "Computes a 64-bit FarmHash fingerprint of the input." },
    { "label": "farmHash64", "documentation": "Computes a 64-bit FarmHash of the input." },
    { "label": "javaHash", "documentation": "Computes a Java-style hash of the input." },
    { "label": "javaHashUTF16LE", "documentation": "Computes a UTF16LE Java-style hash of the input." },
    { "label": "hiveHash", "documentation": "Computes a Hive-compatible hash of the input." },
    { "label": "metroHash64", "documentation": "Computes a 64-bit MetroHash of the input." },
    { "label": "jumpConsistentHash", "documentation": "Implements a jump consistent hashing algorithm." },
    { "label": "kostikConsistentHash", "documentation": "Implements Kostik's consistent hashing algorithm." },
    { "label": "murmurHash2_32", "documentation": "Computes a 32-bit MurmurHash2 of the input." },
    { "label": "murmurHash2_64", "documentation": "Computes a 64-bit MurmurHash2 of the input." },
    { "label": "gccMurmurHash", "documentation": "Computes a GCC-style MurmurHash." },
    { "label": "kafkaMurmurHash", "documentation": "Computes a Kafka-compatible MurmurHash." },
    { "label": "murmurHash3_32", "documentation": "Computes a 32-bit MurmurHash3 of the input." },
    { "label": "murmurHash3_64", "documentation": "Computes a 64-bit MurmurHash3 of the input." },
    { "label": "murmurHash3_128", "documentation": "Computes a 128-bit MurmurHash3 of the input." },
    { "label": "xxh3", "documentation": "Computes an XXH3 hash of the input." },
    { "label": "xxHash32", "documentation": "Computes a 32-bit XXHash of the input." },
    { "label": "xxHash64", "documentation": "Computes a 64-bit XXHash of the input." },
    { "label": "ngramSimHash", "documentation": "Computes an n-gram SimHash of the input." },
    { "label": "ngramSimHashCaseInsensitive", "documentation": "Computes a case-insensitive n-gram SimHash of the input." },
    { "label": "ngramSimHashUTF8", "documentation": "Computes an n-gram SimHash of UTF-8 input." },
    { "label": "ngramSimHashCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive n-gram SimHash of UTF-8 input." },
    { "label": "wordShingleSimHash", "documentation": "Computes a word shingle SimHash of the input." },
    { "label": "wordShingleSimHashCaseInsensitive", "documentation": "Computes a case-insensitive word shingle SimHash of the input." },
    { "label": "wordShingleSimHashUTF8", "documentation": "Computes a word shingle SimHash of UTF-8 input." },
    { "label": "wordShingleSimHashCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive word shingle SimHash of UTF-8 input." },
    { "label": "wyHash64", "documentation": "Computes a 64-bit WyHash of the input." },
    { "label": "ngramMinHash", "documentation": "Computes an n-gram MinHash of the input." },
    { "label": "ngramMinHashCaseInsensitive", "documentation": "Computes a case-insensitive n-gram MinHash of the input." },
    { "label": "ngramMinHashUTF8", "documentation": "Computes an n-gram MinHash of UTF-8 input." },
    { "label": "ngramMinHashCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive n-gram MinHash of UTF-8 input." },
    { "label": "ngramMinHashArg", "documentation": "Computes an n-gram MinHash with arguments for the input." },
    { "label": "ngramMinHashArgCaseInsensitive", "documentation": "Computes a case-insensitive n-gram MinHash with arguments for the input." },
    { "label": "ngramMinHashArgUTF8", "documentation": "Computes an n-gram MinHash with arguments for UTF-8 input." },
    { "label": "ngramMinHashArgCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive n-gram MinHash with arguments for UTF-8 input." },
    { "label": "wordShingleMinHash", "documentation": "Computes a word shingle MinHash of the input." },
    { "label": "wordShingleMinHashCaseInsensitive", "documentation": "Computes a case-insensitive word shingle MinHash of the input." },
    { "label": "wordShingleMinHashUTF8", "documentation": "Computes a word shingle MinHash of UTF-8 input." },
    { "label": "wordShingleMinHashCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive word shingle MinHash of UTF-8 input." },
    { "label": "wordShingleMinHashArg", "documentation": "Computes a word shingle MinHash with arguments for the input." },
    { "label": "wordShingleMinHashArgCaseInsensitive", "documentation": "Computes a case-insensitive word shingle MinHash with arguments for the input." },
    { "label": "wordShingleMinHashArgUTF8", "documentation": "Computes a word shingle MinHash with arguments for UTF-8 input." },
    { "label": "wordShingleMinHashArgCaseInsensitiveUTF8", "documentation": "Computes a case-insensitive word shingle MinHash with arguments for UTF-8 input." },
    { "label": "sqidEncode", "documentation": "Encodes a value into a SQID." },
    { "label": "sqidDecode", "documentation": "Decodes a SQID back into its original value." },

    // in
    { "label": "IN", "documentation": "Checks if a value exists in a set or result of a subquery." },
    { "label": "NOT IN", "documentation": "Checks if a value does not exist in a set or result of a subquery." },
    { "label": "GLOBAL IN", "documentation": "Checks if a value exists in a distributed subquery with global scope." },
    { "label": "GLOBAL NOT IN", "documentation": "Checks if a value does not exist in a distributed subquery with global scope." },

    // ip functions 

    { "label": "IPv4NumToString", "documentation": "Converts an IPv4 number to its string representation." },
    { "label": "IPv4StringToNum", "documentation": "Converts an IPv4 string representation to a number." },
    { "label": "IPv4StringToNumOrDefault", "documentation": "Converts an IPv4 string to a number, or returns a default value if invalid." },
    { "label": "IPv4StringToNumOrNull", "documentation": "Converts an IPv4 string to a number, or returns NULL if invalid." },
    { "label": "IPv4NumToStringClassC", "documentation": "Converts an IPv4 number to its Class C string representation." },
    { "label": "IPv6NumToString", "documentation": "Converts an IPv6 number to its string representation." },
    { "label": "IPv6StringToNum", "documentation": "Converts an IPv6 string representation to a number." },
    { "label": "IPv6StringToNumOrDefault", "documentation": "Converts an IPv6 string to a number, or returns a default value if invalid." },
    { "label": "IPv6StringToNumOrNull", "documentation": "Converts an IPv6 string to a number, or returns NULL if invalid." },
    { "label": "IPv4ToIPv6", "documentation": "Converts an IPv4 address to its IPv6 representation." },
    { "label": "cutIPv6", "documentation": "Cuts bytes from an IPv6 address or from its embedded IPv4 part." },
    { "label": "IPv4CIDRToRange", "documentation": "Converts an IPv4 CIDR notation to its range of IPs." },
    { "label": "IPv6CIDRToRange", "documentation": "Converts an IPv6 CIDR notation to its range of IPs." },
    { "label": "toIPv4", "documentation": "Converts a string or integer to an IPv4 address." },
    { "label": "toIPv4OrDefault", "documentation": "Converts a string or integer to an IPv4 address, or returns a default value if invalid." },
    { "label": "toIPv4OrNull", "documentation": "Converts a string or integer to an IPv4 address, or returns NULL if invalid." },
    { "label": "toIPv4OrZero", "documentation": "Converts a string or integer to an IPv4 address, or returns 0 if invalid." },
    { "label": "toIPv6", "documentation": "Converts a string or integer to an IPv6 address." },
    { "label": "toIPv6OrDefault", "documentation": "Converts a string or integer to an IPv6 address, or returns a default value if invalid." },
    { "label": "toIPv6OrNull", "documentation": "Converts a string or integer to an IPv6 address, or returns NULL if invalid." },
    { "label": "toIPv6OrZero", "documentation": "Converts a string or integer to an IPv6 address, or returns 0 if invalid." },
    { "label": "isIPv4String", "documentation": "Checks if a string is a valid IPv4 address." },
    { "label": "isIPv6String", "documentation": "Checks if a string is a valid IPv6 address." },
    { "label": "isIPAddressInRange", "documentation": "Checks if an IP address is within a specified range." },

    // json

    { "label": "simpleJSONHas", "documentation": "Checks if a JSON string contains a specific key." },
    { "label": "simpleJSONExtractUInt", "documentation": "Extracts an unsigned integer value from a JSON string by key." },
    { "label": "simpleJSONExtractInt", "documentation": "Extracts an integer value from a JSON string by key." },
    { "label": "simpleJSONExtractFloat", "documentation": "Extracts a floating-point value from a JSON string by key." },
    { "label": "simpleJSONExtractBool", "documentation": "Extracts a boolean value from a JSON string by key." },
    { "label": "simpleJSONExtractRaw", "documentation": "Extracts raw JSON data by key." },
    { "label": "simpleJSONExtractString", "documentation": "Extracts a string value from a JSON string by key." },
    { "label": "JSONExtract functions", "documentation": "A family of functions for extracting various types from JSON." },
    { "label": "isValidJSON", "documentation": "Checks if a string is a valid JSON document." },
    { "label": "JSONHas", "documentation": "Checks if a JSON object contains a specific key." },
    { "label": "JSONLength", "documentation": "Returns the number of elements in a JSON array or keys in a JSON object." },
    { "label": "JSONType", "documentation": "Returns the type of a JSON value (e.g., object, array, string, etc.)." },
    { "label": "JSONExtractUInt", "documentation": "Extracts an unsigned integer value from a JSON document." },
    { "label": "JSONExtractInt", "documentation": "Extracts an integer value from a JSON document." },
    { "label": "JSONExtractFloat", "documentation": "Extracts a floating-point value from a JSON document." },
    { "label": "JSONExtractBool", "documentation": "Extracts a boolean value from a JSON document." },
    { "label": "JSONExtractString", "documentation": "Extracts a string value from a JSON document." },
    { "label": "JSONExtract", "documentation": "Extracts a value of any type from a JSON document." },
    { "label": "JSONExtractKeysAndValues", "documentation": "Extracts all keys and values from a JSON object." },
    { "label": "JSONExtractKeys", "documentation": "Extracts all keys from a JSON object." },
    { "label": "JSONExtractRaw", "documentation": "Extracts raw JSON data from a JSON document." },
    { "label": "JSONExtractArrayRaw", "documentation": "Extracts raw JSON data for arrays from a JSON document." },
    { "label": "JSONExtractKeysAndValuesRaw", "documentation": "Extracts keys and values as raw JSON from a JSON document." },
    { "label": "JSON_EXISTS", "documentation": "Checks if a JSON document contains a specific key or path." },
    { "label": "JSON_QUERY", "documentation": "Executes a JSON query and retrieves matching JSON data." },
    { "label": "JSON_VALUE", "documentation": "Extracts a value from a JSON document using a specific path." },
    { "label": "toJSONString", "documentation": "Converts a value or object into a JSON-formatted string." },
    { "label": "JSONArrayLength", "documentation": "Returns the number of elements in a JSON array." },
    { "label": "jsonMergePatch", "documentation": "Applies a JSON merge patch to a JSON document." },
    { "label": "JSONAllPaths", "documentation": "Retrieves all paths in a JSON document." },
    { "label": "JSONAllPathsWithTypes", "documentation": "Retrieves all paths and their types in a JSON document." },
    { "label": "JSONDynamicPaths", "documentation": "Retrieves dynamic paths in a JSON document." },
    { "label": "JSONDynamicPathsWithTypes", "documentation": "Retrieves dynamic paths and their types in a JSON document." },
    { "label": "JSONSharedDataPaths", "documentation": "Retrieves shared data paths from multiple JSON documents." },
    { "label": "JSONSharedDataPathsWithTypes", "documentation": "Retrieves shared data paths with types from multiple JSON documents." },

    // logical

    { "label": "AND", "documentation": "Performs a logical AND operation between two conditions." },
    { "label": "OR", "documentation": "Performs a logical OR operation between two conditions." },
    { "label": "NOT", "documentation": "Performs a logical NOT operation to invert a condition." },
    { "label": "XOR", "documentation": "Performs a logical XOR (exclusive OR) operation between two conditions." },

    // map functions

    { "label": "map", "documentation": "Creates a map from a sequence of key-value pairs." },
    { "label": "mapFromArrays", "documentation": "Creates a map from two arrays: one for keys and one for values." },
    { "label": "extractKeyValuePairs", "documentation": "Extracts key-value pairs from a map or similar structure." },
    { "label": "extractKeyValuePairsWithEscaping", "documentation": "Extracts key-value pairs with support for escaping special characters." },
    { "label": "mapAdd", "documentation": "Adds key-value pairs from one map to another." },
    { "label": "mapSubtract", "documentation": "Removes key-value pairs of one map from another." },
    { "label": "mapPopulateSeries", "documentation": "Populates a map with a series of keys and corresponding values." },
    { "label": "mapContains", "documentation": "Checks if a map contains a specified key." },
    { "label": "mapKeys", "documentation": "Returns the keys of a map as an array." },
    { "label": "mapValues", "documentation": "Returns the values of a map as an array." },
    { "label": "mapContainsKeyLike", "documentation": "Checks if a map contains a key that matches a specific pattern." },
    { "label": "mapExtractKeyLike", "documentation": "Extracts values for keys matching a specific pattern." },
    { "label": "mapApply", "documentation": "Applies a function to all values in a map." },
    { "label": "mapFilter", "documentation": "Filters key-value pairs in a map based on a condition." },
    { "label": "mapUpdate", "documentation": "Updates values in a map based on a function or condition." },
    { "label": "mapConcat", "documentation": "Concatenates multiple maps into one." },
    { "label": "mapExists", "documentation": "Checks if any key-value pair in a map satisfies a condition." },
    { "label": "mapAll", "documentation": "Checks if all key-value pairs in a map satisfy a condition." },
    { "label": "mapSort", "documentation": "Sorts the key-value pairs of a map based on a function or key order." },
    { "label": "mapPartialSort", "documentation": "Partially sorts the key-value pairs of a map up to a specified limit." },
    { "label": "mapReverseSort", "documentation": "Sorts the key-value pairs of a map in reverse order based on a function or key order." },
    { "label": "mapPartialReverseSort", "documentation": "Partially sorts the key-value pairs of a map in reverse order up to a specified limit." },

    // mathematical functions

    { "label": "e", "documentation": "The mathematical constant e (base of the natural logarithm)." },
    { "label": "pi", "documentation": "The mathematical constant π (pi)." },
    { "label": "exp", "documentation": "Calculates the exponential function of a number (e^x)." },
    { "label": "log", "documentation": "Calculates the natural logarithm (base e) of a number." },
    { "label": "exp2", "documentation": "Calculates 2 raised to the power of the input." },
    { "label": "intExp2", "documentation": "Calculates 2 raised to the power of an integer input." },
    { "label": "log2", "documentation": "Calculates the logarithm base 2 of a number." },
    { "label": "exp10", "documentation": "Calculates 10 raised to the power of the input." },
    { "label": "intExp10", "documentation": "Calculates 10 raised to the power of an integer input." },
    { "label": "log10", "documentation": "Calculates the logarithm base 10 of a number." },
    { "label": "sqrt", "documentation": "Calculates the square root of a number." },
    { "label": "cbrt", "documentation": "Calculates the cube root of a number." },
    { "label": "erf", "documentation": "Calculates the error function of the input." },
    { "label": "erfc", "documentation": "Calculates the complementary error function of the input." },
    { "label": "lgamma", "documentation": "Calculates the natural logarithm of the absolute value of the gamma function." },
    { "label": "tgamma", "documentation": "Calculates the gamma function of the input." },
    { "label": "sin", "documentation": "Calculates the sine of an angle in radians." },
    { "label": "cos", "documentation": "Calculates the cosine of an angle in radians." },
    { "label": "tan", "documentation": "Calculates the tangent of an angle in radians." },
    { "label": "asin", "documentation": "Calculates the arcsine of a number." },
    { "label": "acos", "documentation": "Calculates the arccosine of a number." },
    { "label": "atan", "documentation": "Calculates the arctangent of a number." },
    { "label": "pow", "documentation": "Raises a base to a specified exponent (base^exponent)." },
    { "label": "cosh", "documentation": "Calculates the hyperbolic cosine of a number." },
    { "label": "acosh", "documentation": "Calculates the inverse hyperbolic cosine of a number." },
    { "label": "sinh", "documentation": "Calculates the hyperbolic sine of a number." },
    { "label": "asinh", "documentation": "Calculates the inverse hyperbolic sine of a number." },
    { "label": "tanh", "documentation": "Calculates the hyperbolic tangent of a number." },
    { "label": "atanh", "documentation": "Calculates the inverse hyperbolic tangent of a number." },
    { "label": "atan2", "documentation": "Calculates the arctangent of y/x using the signs of both arguments to determine the correct quadrant." },
    { "label": "hypot", "documentation": "Calculates the Euclidean distance from the origin to a point (sqrt(x^2 + y^2))." },
    { "label": "log1p", "documentation": "Calculates the natural logarithm of 1 + x accurately for small x." },
    { "label": "sign", "documentation": "Returns the sign of a number (-1 for negative, 0 for zero, 1 for positive)." },
    { "label": "sigmoid", "documentation": "Calculates the sigmoid function of a number (1 / (1 + e^-x))." },
    { "label": "degrees", "documentation": "Converts an angle from radians to degrees." },
    { "label": "radians", "documentation": "Converts an angle from degrees to radians." },
    { "label": "factorial", "documentation": "Calculates the factorial of a number (n!)." },
    { "label": "width_bucket", "documentation": "Assigns a number to a bucket according to its position within a range and bucket count." },
    { "label": "proportionsZTest", "documentation": "Performs a Z-test for proportions on two samples." },

    // nullable


    { "label": "isNull", "documentation": "Checks if the value is NULL." },
    { "label": "isNullable", "documentation": "Checks if a column or value is of a nullable type." },
    { "label": "isNotNull", "documentation": "Checks if the value is NOT NULL." },
    { "label": "isNotDistinctFrom", "documentation": "Checks if two values are not distinct, treating NULLs as equal." },
    { "label": "isZeroOrNull", "documentation": "Checks if the value is zero or NULL." },
    { "label": "coalesce", "documentation": "Returns the first non-NULL value from the list of arguments." },
    { "label": "ifNull", "documentation": "Returns the first argument if it is not NULL, otherwise returns the second argument." },
    { "label": "nullIf", "documentation": "Returns NULL if the two arguments are equal, otherwise returns the first argument." },
    { "label": "assumeNotNull", "documentation": "Treats a nullable value as not nullable, potentially causing runtime errors if the value is NULL." },
    { "label": "toNullable", "documentation": "Converts a non-nullable value or column to a nullable type." },

    // other (misc)


    { "label": "hostName", "documentation": "Returns the name of the host where the query is executed." },
    { "label": "getMacro", "documentation": "Retrieves the value of a configured macro." },
    { "label": "fqdn", "documentation": "Returns the fully qualified domain name of the server." },
    { "label": "basename", "documentation": "Extracts the base name from a file path." },
    { "label": "visibleWidth", "documentation": "Calculates the visible width of a string considering special characters." },
    { "label": "toTypeName", "documentation": "Returns the type name of a given value or column." },
    { "label": "blockSize", "documentation": "Returns the size of the current data block in rows." },
    { "label": "byteSize", "documentation": "Returns the byte size of a value or column." },
    { "label": "materialize", "documentation": "Converts a constant value to a full column." },
    { "label": "ignore", "documentation": "Ignores the result of a query or computation." },
    { "label": "sleep", "documentation": "Pauses query execution for a specified number of seconds." },
    { "label": "sleepEachRow", "documentation": "Pauses query execution for a specified time per row." },
    { "label": "currentDatabase", "documentation": "Returns the name of the current database." },
    { "label": "currentUser", "documentation": "Returns the name of the current user." },
    { "label": "currentSchemas", "documentation": "Returns the current schema settings." },
    { "label": "isConstant", "documentation": "Checks if a value or column is constant." },
    { "label": "hasColumnInTable", "documentation": "Checks if a column exists in a specified table." },
    { "label": "hasThreadFuzzer", "documentation": "Checks if the thread fuzzer is enabled." },
    { "label": "bar", "documentation": "Generates a visual representation (progress bar) for a value." },
    { "label": "transform", "documentation": "Maps values from one array to another using a transformation array." },
    { "label": "formatReadableDecimalSize", "documentation": "Formats a number into a human-readable decimal size string." },
    { "label": "formatReadableSize", "documentation": "Formats a number into a human-readable size string." },
    { "label": "formatReadableQuantity", "documentation": "Formats a number into a human-readable quantity." },
    { "label": "formatReadableTimeDelta", "documentation": "Formats a time duration into a human-readable string." },
    { "label": "parseReadableSize", "documentation": "Parses a human-readable size string into bytes." },
    { "label": "least", "documentation": "Returns the smallest value among the arguments." },
    { "label": "greatest", "documentation": "Returns the largest value among the arguments." },
    { "label": "uptime", "documentation": "Returns the server uptime in seconds." },
    { "label": "version", "documentation": "Returns the server version." },
    { "label": "buildId", "documentation": "Returns the server build ID." },
    { "label": "blockNumber", "documentation": "Returns the block number of the current row." },
    { "label": "rowNumberInBlock", "documentation": "Returns the row number within the current block." },
    { "label": "rowNumberInAllBlocks", "documentation": "Returns the row number across all processed blocks." },
    { "label": "neighbor", "documentation": "Returns the value of a neighboring row based on an offset." },
    { "label": "runningDifference", "documentation": "Calculates the difference between consecutive rows." },
    { "label": "MACNumToString", "documentation": "Converts a MAC address number to its string representation." },
    { "label": "getSizeOfEnumType", "documentation": "Returns the size of an ENUM type." },
    { "label": "toColumnTypeName", "documentation": "Returns the type name of a column." },
    { "label": "filesystemAvailable", "documentation": "Returns the available filesystem space in bytes." },
    { "label": "initializeAggregation", "documentation": "Initializes an aggregation state." },
    { "label": "finalizeAggregation", "documentation": "Finalizes an aggregation state to compute the result." },
    { "label": "joinGet", "documentation": "Retrieves a value from a JOIN dictionary." },
    { "label": "identity", "documentation": "Returns the input value unchanged." },
    { "label": "getSetting", "documentation": "Retrieves the value of a server setting." },
    { "label": "tcpPort", "documentation": "Returns the TCP port on which the server is listening." },
    { "label": "currentProfiles", "documentation": "Returns the current profiles for the session." },
    { "label": "getOSKernelVersion", "documentation": "Returns the operating system kernel version." },
    { "label": "zookeeperSessionUptime", "documentation": "Returns the uptime of the current ZooKeeper session in seconds." },
    { "label": "generateRandomStructure", "documentation": "Generates a random data structure." },
    { "label": "showCertificate", "documentation": "Displays the SSL certificate used by the server." },
    { "label": "variantElement", "documentation": "Extracts an element from a variant value." },
    { "label": "transactionID", "documentation": "Returns the transaction ID for the current session." },
    { "label": "transactionLatestSnapshot", "documentation": "Returns the latest snapshot of the current transaction." },
    { "label": "globalVariable", "documentation": "Accesses a global variable by name." },

    // random


    { "label": "rand", "documentation": "Generates a 32-bit random integer." },
    { "label": "rand64", "documentation": "Generates a 64-bit random integer." },
    { "label": "randCanonical", "documentation": "Generates a random floating-point number in the range [0, 1)." },
    { "label": "randConstant", "documentation": "Generates a constant random value that is the same within a query." },
    { "label": "randUniform", "documentation": "Generates a random number from a uniform distribution within a specified range." },
    { "label": "randNormal", "documentation": "Generates a random number from a normal (Gaussian) distribution." },
    { "label": "randLogNormal", "documentation": "Generates a random number from a log-normal distribution." },
    { "label": "randBinomial", "documentation": "Generates a random number from a binomial distribution." },
    { "label": "randNegativeBinomial", "documentation": "Generates a random number from a negative binomial distribution." },
    { "label": "randPoisson", "documentation": "Generates a random number from a Poisson distribution." },
    { "label": "randBernoulli", "documentation": "Generates a random number from a Bernoulli distribution." },
    { "label": "randExponential", "documentation": "Generates a random number from an exponential distribution." },
    { "label": "randChiSquared", "documentation": "Generates a random number from a chi-squared distribution." },
    { "label": "randStudentT", "documentation": "Generates a random number from a Student's t-distribution." },
    { "label": "randFisherF", "documentation": "Generates a random number from a Fisher's F-distribution." },
    { "label": "randomString", "documentation": "Generates a random string of a specified length using printable ASCII characters." },
    { "label": "randomFixedString", "documentation": "Generates a random string with a fixed specified length." },
    { "label": "randomPrintableASCII", "documentation": "Generates a random string of printable ASCII characters." },
    { "label": "randomStringUTF8", "documentation": "Generates a random UTF-8 encoded string." },
    { "label": "fuzzBits", "documentation": "Generates a random integer with fuzzed bits for testing purposes." },



    // replace in strings
    { "label": "overlay", "documentation": "Replaces a portion of a string with another string starting at a specified position." },
    { "label": "overlayUTF8", "documentation": "Replaces a portion of a UTF-8 string with another string starting at a specified position." },
    { "label": "replaceOne", "documentation": "Replaces the first occurrence of a substring with another substring." },
    { "label": "replaceAll", "documentation": "Replaces all occurrences of a substring with another substring." },
    { "label": "replaceRegexpOne", "documentation": "Replaces the first substring matching a regular expression with another substring." },
    { "label": "replaceRegexpAll", "documentation": "Replaces all substrings matching a regular expression with another substring." },
    { "label": "regexpQuoteMeta", "documentation": "Escapes all special characters in a string to make it safe for use in a regular expression." },
    { "label": "format", "documentation": "Formats a string using placeholders and specified arguments." },
    { "label": "translate", "documentation": "Replaces characters in a string based on a mapping of from-to characters." },
    { "label": "translateUTF8", "documentation": "Replaces characters in a UTF-8 string based on a mapping of from-to characters." },
    { "label": "printf", "documentation": "Formats a string using C-style formatting placeholders." },

    //rounding

    { "label": "floor", "documentation": "Rounds a number down to the nearest integer." },
    { "label": "ceiling", "documentation": "Rounds a number up to the nearest integer." },
    { "label": "truncate", "documentation": "Truncates a number to a specified number of decimal places without rounding." },
    { "label": "round", "documentation": "Rounds a number to a specified number of decimal places using standard rounding." },
    { "label": "roundBankers", "documentation": "Rounds a number to the nearest even number when the number is exactly halfway between two values." },
    { "label": "roundToExp2", "documentation": "Rounds a number to the nearest power of 2." },
    { "label": "roundDuration", "documentation": "Rounds a duration to the nearest specified time interval." },
    { "label": "roundAge", "documentation": "Rounds an age value to the nearest specified interval (e.g., 5 years)." },
    { "label": "roundDown", "documentation": "Rounds a number down to the nearest specified interval." },

    // search in strings

    { "label": "position", "documentation": "Finds the position of a substring in a string, starting from 1." },
    { "label": "locate", "documentation": "An alias for the 'position' function." },
    { "label": "positionCaseInsensitive", "documentation": "Finds the position of a substring in a string, ignoring case." },
    { "label": "positionUTF8", "documentation": "Finds the position of a substring in a UTF-8 encoded string." },
    { "label": "positionCaseInsensitiveUTF8", "documentation": "Finds the position of a substring in a UTF-8 string, ignoring case." },
    { "label": "multiSearchAllPositions", "documentation": "Finds all positions of multiple substrings in a string." },
    { "label": "multiSearchAllPositionsCaseInsensitive", "documentation": "Finds all positions of multiple substrings in a string, ignoring case." },
    { "label": "multiSearchAllPositionsUTF8", "documentation": "Finds all positions of multiple substrings in a UTF-8 string." },
    { "label": "multiSearchAllPositionsCaseInsensitiveUTF8", "documentation": "Finds all positions of multiple substrings in a UTF-8 string, ignoring case." },
    { "label": "multiSearchFirstPosition", "documentation": "Finds the first position of any substring in a string." },
    { "label": "multiSearchFirstPositionCaseInsensitive", "documentation": "Finds the first position of any substring in a string, ignoring case." },
    { "label": "multiSearchFirstPositionUTF8", "documentation": "Finds the first position of any substring in a UTF-8 string." },
    { "label": "multiSearchFirstPositionCaseInsensitiveUTF8", "documentation": "Finds the first position of any substring in a UTF-8 string, ignoring case." },
    { "label": "multiSearchFirstIndex", "documentation": "Finds the index of the first matching substring from a list in a string." },
    { "label": "multiSearchFirstIndexCaseInsensitive", "documentation": "Finds the index of the first matching substring in a string, ignoring case." },
    { "label": "multiSearchFirstIndexUTF8", "documentation": "Finds the index of the first matching substring in a UTF-8 string." },
    { "label": "multiSearchFirstIndexCaseInsensitiveUTF8", "documentation": "Finds the index of the first matching substring in a UTF-8 string, ignoring case." },
    { "label": "multiSearchAny", "documentation": "Checks if any substring in a list exists in a string." },
    { "label": "multiSearchAnyCaseInsensitive", "documentation": "Checks if any substring exists in a string, ignoring case." },
    { "label": "multiSearchAnyUTF8", "documentation": "Checks if any substring exists in a UTF-8 string." },
    { "label": "multiSearchAnyCaseInsensitiveUTF8", "documentation": "Checks if any substring exists in a UTF-8 string, ignoring case." },
    { "label": "match", "documentation": "Matches a string against a pattern using a regular expression." },
    { "label": "multiMatchAny", "documentation": "Checks if any pattern matches a string using regular expressions." },
    { "label": "multiMatchAnyIndex", "documentation": "Returns the index of the first matching pattern using regular expressions." },
    { "label": "multiMatchAllIndices", "documentation": "Returns all indices of matching patterns using regular expressions." },
    { "label": "multiFuzzyMatchAny", "documentation": "Checks if any pattern matches a string using fuzzy matching." },
    { "label": "multiFuzzyMatchAnyIndex", "documentation": "Returns the index of the first fuzzy match in a string." },
    { "label": "multiFuzzyMatchAllIndices", "documentation": "Returns all indices of fuzzy matches in a string." },
    { "label": "extract", "documentation": "Extracts a substring from a string based on a regular expression." },
    { "label": "extractAll", "documentation": "Extracts all substrings from a string based on a regular expression." },
    { "label": "extractAllGroupsHorizontal", "documentation": "Extracts all regex group matches horizontally from a string." },
    { "label": "extractGroups", "documentation": "Extracts regex groups from a string into separate columns." },
    { "label": "extractAllGroupsVertical", "documentation": "Extracts regex group matches vertically from a string." },
    { "label": "LIKE", "documentation": "Checks if a string matches a pattern." },
    { "label": "NOT LIKE", "documentation": "Checks if a string does not match a pattern." },
    { "label": "ILIKE", "documentation": "Checks if a string matches a pattern, ignoring case." },
    { "label": "NOT ILIKE", "documentation": "Checks if a string does not match a pattern, ignoring case." },
    { "label": "ngramDistance", "documentation": "Calculates the n-gram distance between two strings." },
    { "label": "ngramDistanceCaseInsensitive", "documentation": "Calculates the n-gram distance between two strings, ignoring case." },
    { "label": "ngramDistanceUTF8", "documentation": "Calculates the n-gram distance between two UTF-8 strings." },
    { "label": "ngramDistanceCaseInsensitiveUTF8", "documentation": "Calculates the n-gram distance between two UTF-8 strings, ignoring case." },
    { "label": "ngramSearch", "documentation": "Performs n-gram search in a string." },
    { "label": "ngramSearchCaseInsensitive", "documentation": "Performs n-gram search in a string, ignoring case." },
    { "label": "ngramSearchUTF8", "documentation": "Performs n-gram search in a UTF-8 string." },
    { "label": "ngramSearchCaseInsensitiveUTF8", "documentation": "Performs n-gram search in a UTF-8 string, ignoring case." },
    { "label": "countSubstrings", "documentation": "Counts occurrences of a substring in a string." },
    { "label": "countSubstringsCaseInsensitive", "documentation": "Counts occurrences of a substring in a string, ignoring case." },
    { "label": "countSubstringsCaseInsensitiveUTF8", "documentation": "Counts occurrences of a substring in a UTF-8 string, ignoring case." },
    { "label": "countMatches", "documentation": "Counts occurrences of matches based on a regular expression." },
    { "label": "countMatchesCaseInsensitive", "documentation": "Counts regex matches in a string, ignoring case." },
    { "label": "regexpExtract", "documentation": "Extracts a substring using a regular expression." },
    { "label": "hasSubsequence", "documentation": "Checks if a string contains a specific subsequence." },
    { "label": "hasSubsequenceCaseInsensitive", "documentation": "Checks if a string contains a specific subsequence, ignoring case." },
    { "label": "hasSubsequenceUTF8", "documentation": "Checks if a UTF-8 string contains a specific subsequence." },
    { "label": "hasSubsequenceCaseInsensitiveUTF8", "documentation": "Checks if a UTF-8 string contains a specific subsequence, ignoring case." },
    { "label": "hasToken", "documentation": "Checks if a string contains a specific token." },
    { "label": "hasTokenOrNull", "documentation": "Checks if a string contains a token or returns NULL if not found." },
    { "label": "hasTokenCaseInsensitive", "documentation": "Checks if a string contains a token, ignoring case." },
    { "label": "hasTokenCaseInsensitiveOrNull", "documentation": "Checks if a string contains a token, ignoring case, or returns NULL." },

    // splitting strings

    { "label": "splitByChar", "documentation": "Splits a string into an array of substrings using a single character as the delimiter." },
    { "label": "splitByString", "documentation": "Splits a string into an array of substrings using a string as the delimiter." },
    { "label": "splitByRegexp", "documentation": "Splits a string into an array of substrings based on a regular expression." },
    { "label": "splitByWhitespace", "documentation": "Splits a string into an array of substrings based on whitespace." },
    { "label": "splitByNonAlpha", "documentation": "Splits a string into an array of substrings, breaking at non-alphanumeric characters." },
    { "label": "arrayStringConcat", "documentation": "Concatenates elements of a string array into a single string with a specified delimiter." },
    { "label": "alphaTokens", "documentation": "Extracts alphanumeric tokens from a string and returns them as an array." },
    { "label": "extractAllGroups", "documentation": "Extracts all matching groups from a string using a regular expression." },
    { "label": "ngrams", "documentation": "Generates n-grams from a string and returns them as an array." },
    { "label": "tokens", "documentation": "Splits a string into an array of tokens based on a delimiter or regex." },

    //strings

    { "label": "empty", "documentation": "Checks if a string is empty." },
    { "label": "notEmpty", "documentation": "Checks if a string is not empty." },
    { "label": "length", "documentation": "Returns the length of a string in bytes." },
    { "label": "lengthUTF8", "documentation": "Returns the length of a UTF-8 string in characters." },
    { "label": "left", "documentation": "Extracts a substring from the left side of a string." },
    { "label": "leftUTF8", "documentation": "Extracts a substring from the left side of a UTF-8 string." },
    { "label": "leftPad", "documentation": "Pads a string on the left side with a specified character to a specified length." },
    { "label": "leftPadUTF8", "documentation": "Pads a UTF-8 string on the left side with a specified character to a specified length." },
    { "label": "right", "documentation": "Extracts a substring from the right side of a string." },
    { "label": "rightUTF8", "documentation": "Extracts a substring from the right side of a UTF-8 string." },
    { "label": "rightPad", "documentation": "Pads a string on the right side with a specified character to a specified length." },
    { "label": "rightPadUTF8", "documentation": "Pads a UTF-8 string on the right side with a specified character to a specified length." },
    { "label": "lower", "documentation": "Converts a string to lowercase." },
    { "label": "upper", "documentation": "Converts a string to uppercase." },
    { "label": "lowerUTF8", "documentation": "Converts a UTF-8 string to lowercase." },
    { "label": "upperUTF8", "documentation": "Converts a UTF-8 string to uppercase." },
    { "label": "isValidUTF8", "documentation": "Checks if a string is valid UTF-8." },
    { "label": "toValidUTF8", "documentation": "Converts invalid UTF-8 to a valid UTF-8 string by replacing invalid characters." },
    { "label": "repeat", "documentation": "Repeats a string a specified number of times." },
    { "label": "space", "documentation": "Returns a string of spaces with the specified length." },
    { "label": "reverse", "documentation": "Reverses a string." },
    { "label": "reverseUTF8", "documentation": "Reverses a UTF-8 string." },
    { "label": "concat", "documentation": "Concatenates multiple strings into one." },
    { "label": "concatAssumeInjective", "documentation": "Concatenates strings assuming an injective mapping." },
    { "label": "concatWithSeparator", "documentation": "Concatenates strings with a specified separator." },
    { "label": "concatWithSeparatorAssumeInjective", "documentation": "Concatenates strings with a separator, assuming an injective mapping." },
    { "label": "substring", "documentation": "Extracts a substring from a string." },
    { "label": "substringUTF8", "documentation": "Extracts a substring from a UTF-8 string." },
    { "label": "substringIndex", "documentation": "Extracts a substring before or after a specified delimiter." },
    { "label": "substringIndexUTF8", "documentation": "Extracts a substring before or after a specified delimiter in a UTF-8 string." },
    { "label": "appendTrailingCharIfAbsent", "documentation": "Appends a character to the end of a string if it is not already present." },
    { "label": "convertCharset", "documentation": "Converts a string between character sets." },
    { "label": "base58Encode", "documentation": "Encodes a string in Base58." },
    { "label": "base58Decode", "documentation": "Decodes a Base58-encoded string." },
    { "label": "tryBase58Decode", "documentation": "Tries to decode a Base58-encoded string and returns NULL if it fails." },
    { "label": "base64Encode", "documentation": "Encodes a string in Base64." },
    { "label": "base64URLEncode", "documentation": "Encodes a string in Base64 for URL-safe use." },
    { "label": "base64Decode", "documentation": "Decodes a Base64-encoded string." },
    { "label": "base64URLDecode", "documentation": "Decodes a URL-safe Base64-encoded string." },
    { "label": "tryBase64Decode", "documentation": "Tries to decode a Base64-encoded string and returns NULL if it fails." },
    { "label": "tryBase64URLDecode", "documentation": "Tries to decode a URL-safe Base64-encoded string and returns NULL if it fails." },
    { "label": "endsWith", "documentation": "Checks if a string ends with a specified suffix." },
    { "label": "endsWithUTF8", "documentation": "Checks if a UTF-8 string ends with a specified suffix." },
    { "label": "startsWith", "documentation": "Checks if a string starts with a specified prefix." },
    { "label": "startsWithUTF8", "documentation": "Checks if a UTF-8 string starts with a specified prefix." },
    { "label": "trim", "documentation": "Removes whitespace or specified characters from both ends of a string." },
    { "label": "trimLeft", "documentation": "Removes whitespace or specified characters from the left side of a string." },
    { "label": "trimRight", "documentation": "Removes whitespace or specified characters from the right side of a string." },
    { "label": "trimBoth", "documentation": "Alias for the 'trim' function." },
    { "label": "CRC32", "documentation": "Calculates the CRC32 checksum of a string." },
    { "label": "CRC32IEEE", "documentation": "Calculates the IEEE CRC32 checksum of a string." },
    { "label": "CRC64", "documentation": "Calculates the CRC64 checksum of a string." },
    { "label": "normalizeQuery", "documentation": "Normalizes a SQL query by replacing literals with placeholders." },
    { "label": "normalizeQueryKeepNames", "documentation": "Normalizes a query while preserving table and column names." },
    { "label": "normalizedQueryHash", "documentation": "Generates a hash of a normalized query." },
    { "label": "normalizedQueryHashKeepNames", "documentation": "Generates a hash of a normalized query while preserving names." },
    { "label": "normalizeUTF8NFC", "documentation": "Normalizes a UTF-8 string to NFC (Normalization Form C)." },
    { "label": "normalizeUTF8NFD", "documentation": "Normalizes a UTF-8 string to NFD (Normalization Form D)." },
    { "label": "normalizeUTF8NFKC", "documentation": "Normalizes a UTF-8 string to NFKC (Normalization Form KC)." },
    { "label": "normalizeUTF8NFKD", "documentation": "Normalizes a UTF-8 string to NFKD (Normalization Form KD)." },
    { "label": "encodeXMLComponent", "documentation": "Encodes a string as an XML component." },
    { "label": "decodeXMLComponent", "documentation": "Decodes an XML component back into a string." },
    { "label": "decodeHTMLComponent", "documentation": "Decodes an HTML component back into a string." },
    { "label": "extractTextFromHTML", "documentation": "Extracts plain text from an HTML string." },
    { "label": "ascii", "documentation": "Returns the ASCII code of the first character of a string." },
    { "label": "soundex", "documentation": "Returns the Soundex code of a string for phonetic similarity matching." },
    { "label": "punycodeEncode", "documentation": "Encodes a string using Punycode." },
    { "label": "punycodeDecode", "documentation": "Decodes a Punycode-encoded string." },
    { "label": "tryPunycodeDecode", "documentation": "Tries to decode a Punycode-encoded string and returns NULL if it fails." },
    { "label": "idnaEncode", "documentation": "Encodes a string for Internationalized Domain Names (IDNA)." },
    { "label": "tryIdnaEncode", "documentation": "Tries to encode a string for IDNA and returns NULL if it fails." },
    { "label": "idnaDecode", "documentation": "Decodes an IDNA-encoded string." },
    { "label": "byteHammingDistance", "documentation": "Calculates the Hamming distance between two strings at the byte level." },
    { "label": "stringJaccardIndex", "documentation": "Calculates the Jaccard index between two strings." },
    { "label": "stringJaccardIndexUTF8", "documentation": "Calculates the Jaccard index between two UTF-8 strings." },
    { "label": "editDistance", "documentation": "Calculates the edit (Levenshtein) distance between two strings." },
    { "label": "editDistanceUTF8", "documentation": "Calculates the edit (Levenshtein) distance between two UTF-8 strings." },
    { "label": "damerauLevenshteinDistance", "documentation": "Calculates the Damerau-Levenshtein distance between two strings." },
    { "label": "jaroSimilarity", "documentation": "Calculates the Jaro similarity between two strings." },
    { "label": "jaroWinklerSimilarity", "documentation": "Calculates the Jaro-Winkler similarity between two strings." },
    { "label": "initcap", "documentation": "Capitalizes the first letter of each word in a string." },
    { "label": "initcapUTF8", "documentation": "Capitalizes the first letter of each word in a UTF-8 string." },
    { "label": "firstLine", "documentation": "Extracts the first line of a multi-line string." },


    // time series

    { "label": "seriesOutliersDetectTukey", "documentation": "Detects outliers in a time series using Tukey's method." },
    { "label": "seriesPeriodDetectFFT", "documentation": "Detects the period of a time series using Fast Fourier Transform (FFT)." },
    { "label": "seriesDecomposeSTL", "documentation": "Decomposes a time series into trend, seasonality, and residuals using Seasonal-Trend decomposition based on LOESS (STL)." },

    // time window

    { "label": "tumble", "documentation": "Assigns a timestamp to a tumbling window of fixed size." },
    { "label": "tumbleStart", "documentation": "Returns the start time of a tumbling window for a given timestamp." },
    { "label": "tumbleEnd", "documentation": "Returns the end time of a tumbling window for a given timestamp." },
    { "label": "hop", "documentation": "Assigns a timestamp to a hopping window with specified size and hop interval." },
    { "label": "hopStart", "documentation": "Returns the start time of a hopping window for a given timestamp." },
    { "label": "hopEnd", "documentation": "Returns the end time of a hopping window for a given timestamp." },

    //tuple

    { "label": "tuple", "documentation": "Creates a tuple from specified values." },
    { "label": "tupleElement", "documentation": "Retrieves an element from a tuple by its 1-based index." },
    { "label": "untuple", "documentation": "Splits a tuple into its individual components." },
    { "label": "tupleHammingDistance", "documentation": "Calculates the Hamming distance between two tuples." },
    { "label": "tupleToNameValuePairs", "documentation": "Converts a tuple into an array of name-value pairs." },
    { "label": "tupleNames", "documentation": "Returns the names of the elements in a tuple." },
    { "label": "tuplePlus", "documentation": "Adds corresponding elements of two tuples." },
    { "label": "tupleMinus", "documentation": "Subtracts corresponding elements of two tuples." },
    { "label": "tupleMultiply", "documentation": "Multiplies corresponding elements of two tuples." },
    { "label": "tupleDivide", "documentation": "Divides corresponding elements of two tuples." },
    { "label": "tupleNegate", "documentation": "Negates all elements of a tuple." },
    { "label": "tupleMultiplyByNumber", "documentation": "Multiplies all elements of a tuple by a number." },
    { "label": "tupleDivideByNumber", "documentation": "Divides all elements of a tuple by a number." },
    { "label": "tupleConcat", "documentation": "Concatenates two tuples into one." },
    { "label": "tupleIntDiv", "documentation": "Performs integer division on corresponding elements of two tuples." },
    { "label": "tupleIntDivOrZero", "documentation": "Performs integer division on corresponding elements of two tuples, returning zero if the divisor is zero." },
    { "label": "tupleIntDivByNumber", "documentation": "Performs integer division on all elements of a tuple by a number." },
    { "label": "tupleIntDivOrZeroByNumber", "documentation": "Performs integer division on all elements of a tuple by a number, returning zero if the divisor is zero." },
    { "label": "tupleModulo", "documentation": "Calculates the modulo for corresponding elements of two tuples." },
    { "label": "tupleModuloByNumber", "documentation": "Calculates the modulo for all elements of a tuple by a number." },
    { "label": "flattenTuple", "documentation": "Flattens a nested tuple into a single-level tuple." },

    //type conversion

    { "label": "toBool", "documentation": "Converts a value to a Boolean." },
    { "label": "toInt8", "documentation": "Converts a value to an 8-bit signed integer." },
    { "label": "toInt8OrZero", "documentation": "Converts a value to an 8-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt8OrNull", "documentation": "Converts a value to an 8-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt8OrDefault", "documentation": "Converts a value to an 8-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toInt16", "documentation": "Converts a value to a 16-bit signed integer." },
    { "label": "toInt16OrZero", "documentation": "Converts a value to a 16-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt16OrNull", "documentation": "Converts a value to a 16-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt16OrDefault", "documentation": "Converts a value to a 16-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toInt32", "documentation": "Converts a value to a 32-bit signed integer." },
    { "label": "toInt32OrZero", "documentation": "Converts a value to a 32-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt32OrNull", "documentation": "Converts a value to a 32-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt32OrDefault", "documentation": "Converts a value to a 32-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toInt64", "documentation": "Converts a value to a 64-bit signed integer." },
    { "label": "toInt64OrZero", "documentation": "Converts a value to a 64-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt64OrNull", "documentation": "Converts a value to a 64-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt64OrDefault", "documentation": "Converts a value to a 64-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toInt128", "documentation": "Converts a value to a 128-bit signed integer." },
    { "label": "toInt128OrZero", "documentation": "Converts a value to a 128-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt128OrNull", "documentation": "Converts a value to a 128-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt128OrDefault", "documentation": "Converts a value to a 128-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toInt256", "documentation": "Converts a value to a 256-bit signed integer." },
    { "label": "toInt256OrZero", "documentation": "Converts a value to a 256-bit signed integer, or returns zero if conversion fails." },
    { "label": "toInt256OrNull", "documentation": "Converts a value to a 256-bit signed integer, or returns NULL if conversion fails." },
    { "label": "toInt256OrDefault", "documentation": "Converts a value to a 256-bit signed integer, or returns a default value if conversion fails." },
    { "label": "toUInt8", "documentation": "Converts a value to an 8-bit unsigned integer." },
    { "label": "toUInt8OrZero", "documentation": "Converts a value to an 8-bit unsigned integer, or returns zero if conversion fails." },
    { "label": "toUInt8OrNull", "documentation": "Converts a value to an 8-bit unsigned integer, or returns NULL if conversion fails." },
    { "label": "toUInt8OrDefault", "documentation": "Converts a value to an 8-bit unsigned integer, or returns a default value if conversion fails." },
    { "label": "toUInt16", "documentation": "Converts a value to a 16-bit unsigned integer." },
    { "label": "toUInt16OrZero", "documentation": "Converts a value to a 16-bit unsigned integer, or returns zero if conversion fails." },
    { "label": "toUInt16OrNull", "documentation": "Converts a value to a 16-bit unsigned integer, or returns NULL if conversion fails." },
    { "label": "toUInt16OrDefault", "documentation": "Converts a value to a 16-bit unsigned integer, or returns a default value if conversion fails." },
    { "label": "toUInt32", "documentation": "Converts a value to a 32-bit unsigned integer." },
    { "label": "toUInt32OrZero", "documentation": "Converts a value to a 32-bit unsigned integer, or returns zero if conversion fails." },
    { "label": "toUInt32OrNull", "documentation": "Converts a value to a 32-bit unsigned integer, or returns NULL if conversion fails." },
    { "label": "toUInt32OrDefault", "documentation": "Converts a value to a 32-bit unsigned integer, or returns a default value if conversion fails." },
    { "label": "toUInt64", "documentation": "Converts a value to a 64-bit unsigned integer." },
    { "label": "toUInt64OrZero", "documentation": "Converts a value to a 64-bit unsigned integer, or returns zero if conversion fails." },
    { "label": "toUInt64OrNull", "documentation": "Converts a value to a 64-bit unsigned integer, or returns NULL if conversion fails." },
    { "label": "toUInt64OrDefault", "documentation": "Converts a value to a 64-bit unsigned integer, or returns a default value if conversion fails." },
    { "label": "toString", "documentation": "Converts a value to a string." },
    { "label": "toFixedString", "documentation": "Converts a value to a fixed-length string." },
    { "label": "reinterpret", "documentation": "Reinterprets the underlying data of a value as another type without changing the bits." },
    { "label": "CAST", "documentation": "Explicitly casts a value to a specified type." },
    { "label": "accurateCast", "documentation": "Casts a value to a specified type with additional accuracy checks." },
    { "label": "accurateCastOrNull", "documentation": "Casts a value to a specified type, returning NULL if conversion fails." },
    { "label": "toDate", "documentation": "Converts a value to a Date type." },
    { "label": "toDateTime", "documentation": "Converts a value to a DateTime type." },
    { "label": "toUnixTimestamp64Second", "documentation": "Converts a DateTime to a 64-bit Unix timestamp in seconds." },
    { "label": "fromUnixTimestamp64Second", "documentation": "Converts a 64-bit Unix timestamp in seconds to a DateTime." },
    { "label": "formatRow", "documentation": "Formats a row into a human-readable string." },
    { "label": "formatRowNoNewline", "documentation": "Formats a row into a human-readable string without newline characters." },



    // ULID

    { "label": "generateULID", "documentation": "Generates a new ULID (Universally Unique Lexicographically Sortable Identifier)." },
    { "label": "ULIDStringToDateTime", "documentation": "Converts a ULID string to a DateTime value, extracting the timestamp component." },


    // urls

    { "label": "protocol", "documentation": "Extracts the protocol (scheme) from a URL." },
    { "label": "domain", "documentation": "Extracts the domain from a URL." },
    { "label": "domainRFC", "documentation": "Extracts the domain from a URL following RFC standards." },
    { "label": "domainWithoutWWW", "documentation": "Extracts the domain from a URL without the 'www' prefix." },
    { "label": "domainWithoutWWWRFC", "documentation": "Extracts the domain from a URL without the 'www' prefix, following RFC standards." },
    { "label": "topLevelDomain", "documentation": "Extracts the top-level domain from a URL." },
    { "label": "topLevelDomainRFC", "documentation": "Extracts the top-level domain from a URL following RFC standards." },
    { "label": "firstSignificantSubdomain", "documentation": "Extracts the first significant subdomain from a URL." },
    { "label": "firstSignificantSubdomainRFC", "documentation": "Extracts the first significant subdomain from a URL following RFC standards." },
    { "label": "cutToFirstSignificantSubdomain", "documentation": "Cuts a URL to the first significant subdomain." },
    { "label": "cutToFirstSignificantSubdomainRFC", "documentation": "Cuts a URL to the first significant subdomain, following RFC standards." },
    { "label": "cutToFirstSignificantSubdomainWithWWW", "documentation": "Cuts a URL to the first significant subdomain, keeping the 'www' prefix." },
    { "label": "cutToFirstSignificantSubdomainWithWWWRFC", "documentation": "Cuts a URL to the first significant subdomain with 'www', following RFC standards." },
    { "label": "cutToFirstSignificantSubdomainCustom", "documentation": "Cuts a URL to a custom-defined first significant subdomain." },
    { "label": "cutToFirstSignificantSubdomainCustomRFC", "documentation": "Cuts a URL to a custom-defined first significant subdomain, following RFC standards." },
    { "label": "cutToFirstSignificantSubdomainCustomWithWWW", "documentation": "Cuts a URL to a custom-defined first significant subdomain, keeping the 'www' prefix." },
    { "label": "cutToFirstSignificantSubdomainCustomWithWWWRFC", "documentation": "Cuts a URL to a custom-defined first significant subdomain with 'www', following RFC standards." },
    { "label": "firstSignificantSubdomainCustom", "documentation": "Extracts a custom-defined first significant subdomain from a URL." },
    { "label": "firstSignificantSubdomainCustomRFC", "documentation": "Extracts a custom-defined first significant subdomain from a URL, following RFC standards." },
    { "label": "port", "documentation": "Extracts the port number from a URL." },
    { "label": "portRFC", "documentation": "Extracts the port number from a URL following RFC standards." },
    { "label": "path", "documentation": "Extracts the path component of a URL." },
    { "label": "pathFull", "documentation": "Extracts the full path including query strings and fragments from a URL." },
    { "label": "queryString", "documentation": "Extracts the query string component of a URL." },
    { "label": "fragment", "documentation": "Extracts the fragment (hash) component of a URL." },
    { "label": "queryStringAndFragment", "documentation": "Extracts both the query string and fragment from a URL." },
    { "label": "extractURLParameter", "documentation": "Extracts the value of a specific parameter from a URL." },
    { "label": "extractURLParameters", "documentation": "Extracts all parameters and their values from a URL." },
    { "label": "extractURLParameterNames", "documentation": "Extracts all parameter names from a URL." },
    { "label": "URLHierarchy", "documentation": "Generates the hierarchy of a URL by removing path segments one by one." },
    { "label": "URLPathHierarchy", "documentation": "Generates the hierarchy of the path component of a URL." },
    { "label": "encodeURLComponent", "documentation": "Encodes a component of a URL to make it URL-safe." },
    { "label": "decodeURLComponent", "documentation": "Decodes a URL-encoded component." },
    { "label": "encodeURLFormComponent", "documentation": "Encodes a component for use in URL form data." },
    { "label": "decodeURLFormComponent", "documentation": "Decodes a URL form-encoded component." },
    { "label": "netloc", "documentation": "Extracts the network location (domain and port) from a URL." },
    { "label": "cutWWW", "documentation": "Removes the 'www' prefix from a URL." },
    { "label": "cutQueryString", "documentation": "Removes the query string component from a URL." },
    { "label": "cutFragment", "documentation": "Removes the fragment component from a URL." },
    { "label": "cutQueryStringAndFragment", "documentation": "Removes both the query string and fragment components from a URL." },
    { "label": "cutURLParameter", "documentation": "Removes a specific parameter from a URL." },


    //uuids

    { "label": "generateUUIDv4", "documentation": "Generates a random UUID (version 4)." },
    { "label": "generateUUIDv7", "documentation": "Generates a UUID (version 7) based on timestamps." },
    { "label": "empty", "documentation": "Checks if a UUID value is empty." },
    { "label": "notEmpty", "documentation": "Checks if a UUID value is not empty." },
    { "label": "toUUID", "documentation": "Converts a string to a UUID." },
    { "label": "toUUIDOrDefault", "documentation": "Converts a string to a UUID or returns a default value if the conversion fails." },
    { "label": "toUUIDOrNull", "documentation": "Converts a string to a UUID or returns NULL if the conversion fails." },
    { "label": "toUUIDOrZero", "documentation": "Converts a string to a UUID or returns a zero UUID if the conversion fails." },
    { "label": "UUIDStringToNum", "documentation": "Converts a UUID string to its numeric representation." },
    { "label": "UUIDNumToString", "documentation": "Converts a numeric UUID to its string representation." },
    { "label": "UUIDToNum", "documentation": "Converts a UUID to its numeric equivalent." },
    { "label": "UUIDv7ToDateTime", "documentation": "Extracts the timestamp from a UUID (version 7) and converts it to DateTime." },
    { "label": "serverUUID", "documentation": "Returns the UUID of the current server." },
    { "label": "generateSnowflakeID", "documentation": "Generates a new Snowflake ID." },
    { "label": "snowflakeToDateTime", "documentation": "Converts a Snowflake ID to a DateTime value." },
    { "label": "snowflakeToDateTime64", "documentation": "Converts a Snowflake ID to a DateTime64 value." },
    { "label": "dateTimeToSnowflake", "documentation": "Converts a DateTime value to a Snowflake ID." },
    { "label": "dateTime64ToSnowflake", "documentation": "Converts a DateTime64 value to a Snowflake ID." },
    { "label": "snowflakeIDToDateTime", "documentation": "Converts a Snowflake ID to a DateTime value." },
    { "label": "snowflakeIDToDateTime64", "documentation": "Converts a Snowflake ID to a DateTime64 value." },
    { "label": "dateTimeToSnowflakeID", "documentation": "Converts a DateTime value to a Snowflake ID." },
    { "label": "dateTime64ToSnowflakeID", "documentation": "Converts a DateTime64 value to a Snowflake ID." },

    // theta
    { "label": "uniqThetaUnion", "documentation": "Computes the union of multiple Theta Sketches." },
    { "label": "uniqThetaIntersect", "documentation": "Computes the intersection of multiple Theta Sketches." },
    { "label": "uniqThetaNot", "documentation": "Computes the difference between two Theta Sketches (elements in the first but not in the second)." }





















  ],
  aggregateFunctions: [
    { "label": "aggThrow", "documentation": "Throws an exception when used in aggregation, typically for debugging." },
    { "label": "analysisOfVariance", "documentation": "Performs analysis of variance (ANOVA)." },
    { "label": "any", "documentation": "Returns any (arbitrary) value from a group." },
    { "label": "anyHeavy", "documentation": "Returns a frequently occurring value from a group." },
    { "label": "anyLast", "documentation": "Returns the last value encountered in a group." },
    { "label": "approx_top_k", "documentation": "Returns an approximate top-k elements from a group." },
    { "label": "approx_top_sum", "documentation": "Calculates the sum of the top-k elements in a group." },
    { "label": "argMax", "documentation": "Returns the argument associated with the maximum value." },
    { "label": "argMin", "documentation": "Returns the argument associated with the minimum value." },
    { "label": "array_concat_agg", "documentation": "Concatenates arrays from a group into one array." },
    { "label": "avg", "documentation": "Calculates the average (mean) of a group." },
    { "label": "avgWeighted", "documentation": "Calculates a weighted average of a group." },
    { "label": "boundingRatio", "documentation": "Computes the bounding ratio of a group." },
    { "label": "categoricalInformationValue", "documentation": "Calculates the information value for categorical variables." },
    { "label": "contingency", "documentation": "Computes contingency table statistics." },
    { "label": "corr", "documentation": "Calculates the Pearson correlation coefficient." },
    { "label": "corrMatrix", "documentation": "Calculates the correlation matrix for multiple columns." },
    { "label": "corrStable", "documentation": "Calculates a stable Pearson correlation coefficient." },
    { "label": "count", "documentation": "Counts the number of rows in a group." },
    { "label": "covarPop", "documentation": "Calculates the population covariance of two columns." },
    { "label": "covarPopMatrix", "documentation": "Calculates the population covariance matrix for multiple columns." },
    { "label": "covarPopStable", "documentation": "Calculates a stable population covariance of two columns." },
    { "label": "covarSamp", "documentation": "Calculates the sample covariance of two columns." },
    { "label": "covarSampMatrix", "documentation": "Calculates the sample covariance matrix for multiple columns." },
    { "label": "covarSampStable", "documentation": "Calculates a stable sample covariance of two columns." },
    { "label": "cramersV", "documentation": "Calculates Cramér's V statistic for association." },
    { "label": "cramersVBiasCorrected", "documentation": "Calculates bias-corrected Cramér's V statistic." },
    { "label": "deltaSum", "documentation": "Calculates the delta sum of a group." },
    { "label": "deltaSumTimestamp", "documentation": "Calculates the delta sum for timestamps in a group." },
    { "label": "entropy", "documentation": "Calculates the entropy of a group." },
    { "label": "exponentialMovingAverage", "documentation": "Calculates the exponential moving average." },
    { "label": "exponentialTimeDecayedAvg", "documentation": "Calculates the exponential time-decayed average." },
    { "label": "exponentialTimeDecayedCount", "documentation": "Counts elements with exponential time decay." },
    { "label": "exponentialTimeDecayedMax", "documentation": "Finds the maximum value with exponential time decay." },
    { "label": "exponentialTimeDecayedSum", "documentation": "Calculates the sum with exponential time decay." },
    { "label": "first_value", "documentation": "Returns the first value in a group." },
    { "label": "flameGraph", "documentation": "Generates data for flame graphs." },
    { "label": "groupArray", "documentation": "Aggregates values into an array." },
    { "label": "groupArrayInsertAt", "documentation": "Inserts a value into a group array at a specified position." },
    { "label": "groupArrayIntersect", "documentation": "Finds the intersection of arrays in a group." },
    { "label": "groupArrayLast", "documentation": "Aggregates the last values into an array." },
    { "label": "groupArrayMovingAvg", "documentation": "Calculates the moving average for a group array." },
    { "label": "groupArrayMovingSum", "documentation": "Calculates the moving sum for a group array." },
    { "label": "groupArraySample", "documentation": "Samples values from a group array." },
    { "label": "groupArraySorted", "documentation": "Aggregates values into a sorted array." },
    { "label": "groupBitAnd", "documentation": "Performs a bitwise AND operation on grouped values." },
    { "label": "groupBitmap", "documentation": "Aggregates values into a bitmap." },
    { "label": "groupBitmapAnd", "documentation": "Performs a bitwise AND operation on grouped bitmaps." },
    { "label": "groupBitmapOr", "documentation": "Performs a bitwise OR operation on grouped bitmaps." },
    { "label": "groupBitmapXor", "documentation": "Performs a bitwise XOR operation on grouped bitmaps." },
    { "label": "groupBitOr", "documentation": "Performs a bitwise OR operation on grouped values." },
    { "label": "groupBitXor", "documentation": "Performs a bitwise XOR operation on grouped values." },
    { "label": "groupUniqArray", "documentation": "Aggregates unique values into an array." },
    { "label": "intervalLengthSum", "documentation": "Calculates the sum of interval lengths." },
    { "label": "kolmogorovSmirnovTest", "documentation": "Performs the Kolmogorov-Smirnov test for two distributions." },
    { "label": "kurtPop", "documentation": "Calculates the population kurtosis of a group." },
    { "label": "kurtSamp", "documentation": "Calculates the sample kurtosis of a group." },
    { "label": "largestTriangleThreeBuckets", "documentation": "Simplifies a time series using the Largest Triangle Three Buckets algorithm." },
    { "label": "last_value", "documentation": "Returns the last value in a group." },
    { "label": "mannWhitneyUTest", "documentation": "Performs the Mann-Whitney U test for two groups." },
    { "label": "max", "documentation": "Finds the maximum value in a group." },
    { "label": "maxIntersections", "documentation": "Finds the maximum intersections in a group." },
    { "label": "maxIntersectionsPosition", "documentation": "Finds the position of maximum intersections in a group." },
    { "label": "maxMap", "documentation": "Finds the maximum value in a map of aggregated values." },
    { "label": "meanZTest", "documentation": "Performs a Z-test for means." },
    { "label": "median", "documentation": "Finds the median value in a group." },
    { "label": "min", "documentation": "Finds the minimum value in a group." },
    { "label": "minMap", "documentation": "Finds the minimum value in a map of aggregated values." },
    { "label": "quantile", "documentation": "Calculates quantiles for a group." },
    { "label": "quantileTDigest", "documentation": "Calculates quantiles using the TDigest algorithm." },
    { "label": "rankCorr", "documentation": "Calculates the rank correlation coefficient." },
    { "label": "stddevPop", "documentation": "Calculates the population standard deviation of a group." },
    { "label": "stddevSamp", "documentation": "Calculates the sample standard deviation of a group." },
    { "label": "sum", "documentation": "Calculates the sum of values in a group." },
    { "label": "uniq", "documentation": "Counts the number of unique values in a group." },
    { "label": "welchTTest", "documentation": "Performs Welch's t-test for two groups." },
    { "label": "distinctDynamicTypes", "documentation": "Finds distinct dynamic types in a group." },
    { "label": "distinctJSONPaths", "documentation": "Finds distinct JSON paths in a group." },
    { "label": "groupConcat", "documentation": "Concatenates string values in a group." }

    // parametric missing https://clickhouse.com/docs/en/sql-reference/aggregate-functions/parametric-functions
  ]

}

const addingIfSuffix = baseFunctions.aggregateFunctions.map((func) => {return {label: func.label + "If", documentation: ''}});
const addingArraySuffix = baseFunctions.aggregateFunctions.map((func) => {return {label: func.label + "Array", documentation: ''}});
const addingMapSuffix = baseFunctions.aggregateFunctions.map((func) => {return {label: func.label + "Map", documentation: ''}});
const clickHouseFunctions = {regularFunctions: [...baseFunctions.regularFunctions], aggregateFunctions: [...baseFunctions.aggregateFunctions, ...addingIfSuffix, ...addingArraySuffix, ...addingMapSuffix]}  ;

export default clickHouseFunctions;