/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChColumn } from "./schema-contracts";

export function getColumnNamesThatAreNumbers(metadata: ChColumn[]) {
    const numberColumnNames: string[] = [];
    for (let i = 0; i < metadata.length; i++) {
      const column = metadata[i];
      if (column.type === "UInt64" || column.type === "Int64" || column.type === "Int32" || column.type === "UInt32" || column.type === "Float64" || column.type === "Float32") {
        numberColumnNames.push(column.name);
      }
    }
    return numberColumnNames;
}


export function convertNumbersInData(data: any, metadata: ChColumn[]) {
  if (data === null || data === undefined) {
    return data;
  }

  const numberColumnNames = getColumnNamesThatAreNumbers(metadata);

  for (let i = 0; i < data.length; i++) {
    const dataObj = data[i];
    const keys = Object.keys(dataObj);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      if (numberColumnNames.includes(key) && dataObj[key] !== null && dataObj[key] !== undefined) {
        dataObj[key] = Number(dataObj[key]);
      }
    }
  }
  
}