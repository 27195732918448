import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../../utils/logger';
import { isValidDate } from '../../utils/general-utils';

interface TimechartProps {
    visible: boolean;
}

// const series = [
//     {
//         type: 'line',
//         xKey: 'date',
//         yKey: 'value',
//         stroke: 'blue', // Optional styling
//     },
// ];

const axes = [
    {
        type: 'time',
        position: 'bottom',
        label: {
            format: '%Y-%m-%d %H:%M',
        },
    },
    {
        type: 'number',
        position: 'left',
    },
];

const Timechart = forwardRef((props: TimechartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.debug('updateResults: timechart data:', "YpUVU");
        logger.debug(results, "MjfG6");
        if (results.length > 0) {
            const firstRow = results[0];
            const timeColumn = getTimeColumn(firstRow);
            const valueColumns = Object.keys(firstRow).filter(key => key !== timeColumn && !isNaN(Number(firstRow[key])));


            // logger.debug('updateResults: timechart valueKeys:' + JSON.stringify(valueKeys), "YpUV4");
            const data = results.map((row) => {
                const values = {};
                valueColumns.forEach((valueCol) => {
                    values[valueCol] = parseFloat(row[valueCol]);
                });
                return {
                    date: new Date(row[timeColumn]),
                    ...values,
                };
            }).sort((a, b) => a.date - b.date);

            logger.debug('updateResults: timechart data:' + JSON.stringify(data), "ZxiHH");

            const series = valueColumns.map((valueCol) => ({
                type: 'line',
                xKey: 'date',
                yKey: valueCol,
            }));

            logger.debug('updateResults: timechart series:' + JSON.stringify(series), "qeWPz");

            setOptions({
                data: data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                series: series as any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                axes: axes as any,
            });

        } else {
            setOptions({}); // no results
        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
    );

    return <div
        className='results-view'
        style={{
            height: '100%',
            border: 'none',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{ width: '100%', height: '100%', display: 'block' }} /></div>;
});


export default Timechart;



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTimeColumn(firstRow: any) {
    const keys = Object.keys(firstRow);
    const best = keys.map((key, index) => {
        let score = 0;
        const lower = key.toLowerCase();
        const value = firstRow[key];
        if (isValidDate(value)) {
            score += 3;
        }
        if (lower.includes('()')) {
            score -= 1;
        }
        if (lower.includes('time')) {
            score += 1;
        }
        if (lower.includes('date')) {
            score += 1;
        }
        if (lower.includes('day')) {
            score += 1;
        }
        if (lower.includes('month')) {
            score += 1;
        }
        if (lower.includes('year')) {
            score += 1;
        }
        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];

}


