
const BATCH_SIZE = 1;

export enum LogLevel {
  Debug = 1,
  Verbose = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Critical = 6,

}

export class Logger {
  private logBuffer = [];

  minLevel: LogLevel;
  host: string;

  public constructor(minLevel: LogLevel) {
    this.minLevel = minLevel;
    this.host = window.location.hostname;
    console.log('Logger initialized with minLevel:', minLevel, 'host:', this.host);
    
    // private constructor
  }

  public debug(message: string, logId: string): void {
    this.log(LogLevel.Debug, message, logId);
  }

  public verbose(message: string, logId: string): void {
    console.log(`[${this.levelToFriendlyString(LogLevel.Verbose)}] ${logId}`, message);
    this.log(LogLevel.Verbose, message, logId);
  }

  public info(message: string, logId: string): void {
    this.log(LogLevel.Info, message, logId);
  }

  public error(message: string, logId: string): void {
    this.log(LogLevel.Error, message, logId);
  }

  public warn(message: string, logId: string): void {
    this.log(LogLevel.Warn, message, logId);
  }

  public flush(): void {
    this.sendLogsToServer();
  }

  private log(level: number, message: string, logId: string): void {
    const severity = this.levelToFriendlyString(level);
    console.log(`[${severity} ${logId}] `, message);
    if (level < this.minLevel) {
      return;
    }
    const timestamp = new Date().getTime();
    this.logBuffer.push({ timestamp, severity, message, logId, sessionId: this.getSessionId(), host: this.host, service: 'web' });
    if (this.logBuffer.length >= BATCH_SIZE) {
      this.sendLogsToServer();
    }
  }

  private levelToFriendlyString(level: LogLevel): string {
    switch (level) {
      case LogLevel.Debug:
        return 'DEBUG';
      case LogLevel.Verbose:
        return 'VERBOSE';
      case LogLevel.Info:
        return 'INFO';
      case LogLevel.Warn:
        return 'WARN';
      case LogLevel.Error:
        return 'ERROR';
      case LogLevel.Critical:
        return 'CRITICAL';
      default:
        return 'UNKNOWN';
    }
  }

  public getSessionId() {
    let session = sessionStorage.getItem('sessionId');
    if (session) {
      return session;
    }
    session = this.generateSessionID();
    sessionStorage.setItem('sessionId', session);
    console.log('--- session ID:', session);
    return session;
  }

  private sendLogsToServer() {
    if (this.logBuffer.length === 0) return;

    // Make a copy of the buffer and clear it

    const batch = [...this.logBuffer];
    this.logBuffer.length = 0;

    const url = window.location.hostname === 'localhost' ? 'http://localhost:8000/api/logs-proxy' : 'https://obics.io/api/logs-proxy/';
    try {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-id': this.getSessionId(),
        },
        body: JSON.stringify(batch),
      }).then(() => {
        // console.log('Logs sent successfully. response:', response);
      }).catch(error => {
        console.error('Failed to send logs:', error);
      });
    } catch (error) {
      console.error('Failed to send logs:', error);
    }
  }

  private generateSessionID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


}

const logger = new Logger(LogLevel.Info);
export default logger;