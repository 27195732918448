import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logger from './utils/logger';

// somewhere in app startup
const originalWarn = console.warn;
const originalError = console.error;

// Override and capture
console.warn = function(...args) {
  logger.warn(JSON.stringify(args), 'cWarn');
  originalWarn.apply(console, args);
};

console.error = function(...args) {
  logger.error(JSON.stringify(args), 'cErrr');
  originalError.apply(console, args);
};

logger.info('App started. Host: ' + window.location.host, 'lwOgH');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console. log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
