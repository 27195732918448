import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../../utils/logger';
import { getTimeColumn } from './timechart';

interface ScatterchartProps {
    visible: boolean;
}

const axes = [
    {
        type: 'time',
        position: 'bottom',
        label: {
            format: '%Y-%m-%d %H:%M',
        },
    },
    {
        type: 'number',
        position: 'left',
    },
];

const Scatterchart = forwardRef((props: ScatterchartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.debug('updateResults: Scatterchart data:', "MulDx");
        logger.debug(results, "LXBbB");
        if (results.length > 0) {
            const firstRow = results[0];
            const timeColumn = getTimeColumn(firstRow);
            const valueColumns = Object.keys(firstRow).filter(key => key !== timeColumn && !isNaN(Number(firstRow[key])));


            const data = results.map((row) => {
                const values = {};
                valueColumns.forEach((valueCol) => {
                    values[valueCol] = parseFloat(row[valueCol]);
                });
                return {
                    date: new Date(row[timeColumn]),
                    ...values,
                };
            }).sort((a, b) => a.date - b.date);

            logger.debug('updateResults: Scatterchart data:' + JSON.stringify(data), "l4sqa");

            const series = valueColumns.map((valueCol) => ({
                type: 'scatter',
                xKey: 'date',
                yKey: valueCol,
            }));

            logger.debug('updateResults: Scatterchart series:' + JSON.stringify(series), "O2RWp");

            setOptions({
                data: data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                series: series as any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                axes: axes as any,
            });

        } else {
            setOptions({}); // no results
        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
    );

    return <div
        className='results-view'
        style={{
            height: '100%',
            border: 'none',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{ width: '100%', height: '100%', display: 'block' }} /></div>;
});


export default Scatterchart;

