import React, { useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { editor, Position } from 'monaco-editor';
import * as monaco from 'monaco-editor';
import logger from '../utils/logger';
import { getIntellisenseSuggestionsSmart } from './monaco-intellisense-smart';
import { ChTable } from './schema-contracts';
import { getAdjacentLines } from './monaco-utils';


interface MonacoEditorProps {
  ctrlEnterClicked: () => void;
  index: number;
  isSandBox: boolean;
  // height: number;
  chSchema: ChTable[];
}
   
let chSchema: ChTable[] = [];

monaco.languages.registerCompletionItemProvider('sql', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  provideCompletionItems: (model: editor.ITextModel, position: Position, context: any) => {
    try {
      const suggestions = getIntellisenseSuggestionsSmart(model, position, context, chSchema) as never[];
      return { suggestions };
    } catch (error) {
      logger.error('Error in provideCompletionItems: ' + (error && typeof error.toString === 'function' && error.toString()), 'k4uFU');
      return { suggestions: [] };
    }
  },
});


const editorInstance = [];
const MonacoEditor = forwardRef((props: MonacoEditorProps, ref) => {

  const emitCtrlEnterClicked = props.ctrlEnterClicked;

  useImperativeHandle(ref, () => ({
    callSetValue: setValue,
    callGetValue: getValue,
    callGetFullValue: getFullValue,
    callSetCaretAtLastQuery: setCaretAtLastQuery,
    callLayout: () => editorInstance[props.index]?.layout()
    
  }));

  useEffect(() => {
    chSchema = props.chSchema;

    if (!chSchema) return;
    const columnNames = chSchema.flatMap(tbl => tbl.columns.map(c => c.name));
    const tableNames = chSchema.map(tbl => tbl.name);
    const columnAndTables = new RegExp(`\\b(?:${columnNames.concat(tableNames).join('|')})\\b`, 'g');
    monaco.languages.setMonarchTokensProvider('sql', {
      tokenizer: {
        root: [
    
          // additional types with different color: 'operator'(light gray), 'string' (red), 'number' (green), comment (green), constant (red), type (light green)
          [/\b(?:SELECT|FROM|WHERE|INSERT|UPDATE|DELETE|JOIN|INNER JOIN|LEFT JOIN|RIGHT JOIN|GROUP BY|ORDER BY|LIMIT|OFFSET|select|from|where|insert|update|delete|join|inner join|left join|right join|group by|order by|limit|offset)\b/, 'keyword'],
          [/\b(?:WITH|with|DESC|desc|asc|ASC)\b/, 'keyword'],
          [/\b(?:VISUALIZE TIMECHART|VISUALIZE PIECHART|VISUALIZE BARCHART|VISUALIZE AREACHART|visualize timechart|visualize piechart|visualize barchart|visualize areachart)\b/, 'operator'],
          [columnAndTables, 'type'],
    
        ],
      },
    });
    
  }, [props.chSchema]);

  const monacoRef = useRef(null);


  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const handleEditorChange = (_value: any, _event: any) => {
  };

  const getValue = () => {
    const editor = editorInstance[props.index];
    if (!editor) return null;
    
    // const value = editor.getValue();
    const position = editor.getPosition();
    const model = editor.getModel();
    if (!model) return null;
    const lines = model.getLinesContent();
    const adjacentLines = getAdjacentLines(lines, position, true /* removeComments */);
    if (adjacentLines.trim() === '') {
      return editor.getValue();
    } else {
      return adjacentLines;
    }
  };

  const setCaretAtLastQuery = () => {
    const editor = editorInstance[props.index];
    if (!editor) return;

    const model = editor.getModel();
    if (!model) return;

    const lines = model.getLinesContent();
    let lineNumber = lines.length - 1;
    for (let i = lines.length - 1; i >= 0; i--) {
      if (lines[i].trim() !== '') {
        lineNumber = i;
        break;
      }
    }

    // const column = model.getLineMaxColumn(lineNumber);
    editor.setPosition({ lineNumber: lineNumber+1, column: 0 });
    editor.revealLine(lineNumber+1);
  }

  const getFullValue = () => {
    const editor = editorInstance[props.index];
    if (!editor) return null;
    
    return editor.getValue();
  };

  const setValue = (value: string) => {
    editorInstance[props.index]?.setValue(value);
  };

  useEffect(() => {
    if (monacoRef.current) {
      if (editorInstance[props.index]) {
        editorInstance[props.index].dispose();
      }

      editorInstance[props.index] = monaco.editor.create(monacoRef.current, {
        value: `SELECT * FROM Logs ORDER BY timestamp DESC LIMIT 100`,
        language: 'sql',
        scrollbar: { vertical: 'hidden', horizontal: 'auto' },
        minimap: { enabled: false },
        overviewRulerLanes: 0,
        glyphMargin: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 2,
      });
      
      const editor = editorInstance[props.index];
      
      setTimeout(() => {
        editor.focus();
      }, 10);

      const handleResize = () => {
        editor.layout();
      };
  
      // Add resize listener
      window.addEventListener("resize", handleResize);
  

      editor.onChange = handleEditorChange;
      editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, function () {
        logger.info('Ctrl+Enter pressed on index ' + props.index, 'XBDol');
        emitCtrlEnterClicked();
      });

      return () => {
        editor.dispose();
        editorInstance[props.index] = null;
        window.addEventListener("resize", handleResize);
      }
    }

  }, []);



  return (<div ref={monacoRef} style={{  height: '100%', 
      maxWidth: '100%', border: 'none' }} />);
  
});

export default MonacoEditor;

