import React, { useEffect, useRef, useState } from 'react';
import logger from '../utils/logger';
import { createHeadersForApiCall } from '../utils/api-utils';
import { User } from 'firebase/auth';

interface GoodAnswerModalProps {
  prompt: string;
  answer: string;
}

interface ModalProps {
  user: User;
  closeModal: () => void;
  goodAnswerModalProps: GoodAnswerModalProps;
}



const GoodAnswerModal: React.FC<ModalProps> = ({ user, closeModal, goodAnswerModalProps }) => {

  const [inProgress, setInProgress] = useState(false);

  logger.info(`Modal (good answer) opened`, "LNFgN");
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    const promptValue = (document.getElementById('prompt-field') as HTMLInputElement).value;
    const answerValue = (document.getElementById('answer-field') as HTMLInputElement).value;

    if (!promptValue || !answerValue) {
      return;
    }

    if (inProgress) {
      return;
    }

    logger.info(`Modal (good answer): submit clicked`, "VlvVM");
    console.log("promptValue", promptValue, "answerValue", answerValue);
    setInProgress(true);

    try {
      const isLocalhost = window.location.hostname === 'localhost'; // UNCOMMENT DURING DEBUG IF NEEDED 
      const url = isLocalhost ? 'http://localhost:8000/api/ai/good-answers-add' : 'https://obics.io/api/ai/good-answers-add/';
      const headers = await createHeadersForApiCall(user, logger);
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify([{ prompt: promptValue, answer: answerValue }]),
        headers,
      });
      console.log("Modal (good answer): response after request =", res);

      closeModal();
    } finally {
      setInProgress(false);
    }
  }

  const promptFieldRef = useRef(null); // Create a ref for the prompt input field

  useEffect(() => {
    // Focus the prompt input field when the component mounts
    if (promptFieldRef.current) {
      promptFieldRef.current.focus();
    }
    setTimeout(() => {
      if (promptFieldRef.current) {
        promptFieldRef.current.focus();
      }
    }, 150);
  }, []); // Run only once when the component is mounted

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <h2 className="text-xl font-semibold mb-4">Add an example of a good answer</h2>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Prompt:</label>
            <input
              id="prompt-field"
              ref={promptFieldRef}
              type="text"
              defaultValue={goodAnswerModalProps.prompt}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Answer(query):</label>
            <textarea
              id="answer-field"
              defaultValue={goodAnswerModalProps.answer}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={4}
            />

          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={closeModal}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={"px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" + (inProgress ? " cursor-not-allowed" : "")}
            >
              <span className={inProgress ? "spinner" : ""}>
                {inProgress ? "" : "OK"}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoodAnswerModal;
