/* eslint-disable @typescript-eslint/no-unused-vars */
import * as monaco from 'monaco-editor';
import { ChTable } from './schema-contracts';
import { findLastIndex } from '../utils/general-utils';
import clickHouseFunctions from './monaco-intellisense-keywords';
import { getAdjacentLines } from './monaco-utils';
import { vis } from './query';

export interface IntellisenseSuggestion {
  label: string;
  kind: monaco.languages.CompletionItemKind;
  insertText: string;
  documentation: string;
  sortText?: string;
}

const clickhouseKeywords = [
  'SELECT', 'FROM', 'WHERE', 'BY', 'LIMIT', 'JOIN', 'WITH', 'TIMECHART', 'PIECHART', 'VISUALIZE', 'AS','ON'
];


export function getIntellisenseSuggestionsSmart(model: monaco.editor.ITextModel, position: monaco.Position, _context: unknown, chSchema: ChTable[]): IntellisenseSuggestion[] {
  
  const lines = model.getLinesContent();
  let startLine = 0;
  for (let i = position.lineNumber - 1; i >= 0; i--) {
    if (lines[i].trim() === '') {
      startLine = i + 1;
      break;
    }
  }

  const textUntilPosition = model.getValueInRange({
    startLineNumber: startLine,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: position.column
  });


  const words = textUntilPosition.split(/[\s,().;]+/).filter(Boolean);
  let lastWord = words[words.length - 1]?.toUpperCase() || '';
  if (lastWord.length <= 1 && words.length > 1) {
    lastWord = words[words.length - 2]?.toUpperCase();
  }
  const lastCHKeywordIndex = findLastIndex(words, w => clickhouseKeywords.includes(w.toUpperCase()));
  let lastCHKeyword = lastCHKeywordIndex >= 0 ? words[lastCHKeywordIndex].toUpperCase() : '';
  if (lastCHKeyword.toUpperCase() === 'BY') {
    // Changes lastCHKeyword TO ORDER BY or GROUP BY
    lastCHKeyword = lastCHKeywordIndex >= 1 ? words[lastCHKeywordIndex - 1].toUpperCase() + ' ' + lastCHKeyword : lastCHKeyword;
  }

  if (lastCHKeyword.toUpperCase() === 'AS' && lastWord.toUpperCase() !== 'AS') {
    const indexOfASInWords = findLastIndex(words, w => w.toUpperCase() === 'AS');
    const nextWord = words[indexOfASInWords + 1];
    if (words.length > indexOfASInWords + 2 || nextWord.includes(',')
      || textUntilPosition[textUntilPosition.length - 2] === ' ' || textUntilPosition[textUntilPosition.length - 2] === '\n') {
        const lastIndexOfSelect = findLastIndex(words, w => w.toUpperCase() === 'SELECT');
        const lastIndexOfFrom = findLastIndex(words, w => w.toUpperCase() === 'FROM');
        const lastIndexOfJoin = findLastIndex(words, w => w.toUpperCase() === 'JOIN');
        if (lastIndexOfSelect > lastIndexOfFrom && lastIndexOfSelect > lastIndexOfJoin) {
          lastCHKeyword = 'SELECT';
        } else if (lastIndexOfFrom > lastIndexOfJoin && lastIndexOfFrom > lastIndexOfSelect) {
          lastCHKeyword = 'FROM';
        } else {
          lastCHKeyword = 'JOIN';
        }
    }
  }

  const withAliases = getWithAliases(words);
  console.log('withAliases', withAliases);
  const clickhouseTables = (chSchema ? chSchema.map(tbl => ({ name: tbl.name, columns: tbl.columns.map(c => c.name) })) : [])
    .concat(withAliases.map(alias => ({ name: alias, columns: [] })));

  let suggestions: IntellisenseSuggestion[] = [];
  console.log('lastCHKeyword', lastCHKeyword);
  suggestions = addNextOperators(lastCHKeyword, suggestions);// Basic operators WHERE,FROM, SELECT, etc.

  // If the last significant token is FROM, suggest table names
  if (lastCHKeyword === 'FROM' || lastCHKeyword === 'JOIN') {
    suggestions = addTableNames(suggestions, clickhouseTables);
  } else if (lastCHKeyword === 'SELECT' || lastCHKeyword === 'WHERE' || lastCHKeyword === 'AND') {
    // columns
    suggestions = addAsAliases(model, position, suggestions);
    suggestions = addChColumns(suggestions, clickhouseTables);
    suggestions = addRegularFunctions(suggestions);

    if (lastWord === 'SELECT') {
      suggestions = addAggregateFunctions(suggestions);
    }
  } else if (lastCHKeyword === 'ORDER BY' || lastCHKeyword === 'GROUP BY' || lastCHKeyword === 'ON') {
    suggestions = addAsAliases(model, position, suggestions);
    suggestions = addChColumns(suggestions, clickhouseTables);
    suggestions = addRegularFunctions(suggestions);
  }

  return suggestions;
}



function addAsAliases(model: monaco.editor.ITextModel, position: monaco.Position, suggestions: IntellisenseSuggestion[]) {
  const lines = getAdjacentLines(model.getLinesContent(), position, true /* removeComments */);
  const words = lines.split(/[\s,().;]+/).filter(Boolean);
  for (let i = 0; i < words.length; i++) {
    if (words.length > i && words[i].toUpperCase() === 'AS') {
      const nextWord = words[i + 1];
      if (nextWord) {
        suggestions.push({
          label: nextWord,
          kind: monaco.languages.CompletionItemKind.Field,
          insertText: nextWord,
          sortText: 'a' + nextWord,
          documentation: ''
        });
      };
    }
  }
  return suggestions;
}

function addNextOperators(lastCHKeyword: string, suggestions: IntellisenseSuggestion[]) {
  if (lastCHKeyword === 'AS') {
    return suggestions;
  }

  const nextOperators: string[] = getNextOperator(lastCHKeyword); //getClickHouseKeywordsSuggestions(lastWord, lastCHKeyword);
  suggestions = suggestions.concat(nextOperators.map(op => ({
    label: op.toUpperCase(),
    kind: monaco.languages.CompletionItemKind.Keyword,
    insertText: op.toUpperCase(),
    documentation: '',
    sortText: 'aa' + op.toUpperCase()
  })));
  suggestions = suggestions.concat(nextOperators.filter(op => op === 'FROM').map(op => ({
    label: op.toLowerCase(),
    kind: monaco.languages.CompletionItemKind.Keyword,
    insertText: op.toUpperCase(),
    documentation: '',
    sortText: 'ab' + op.toUpperCase()
  })));
  return suggestions;
}

function addTableNames(suggestions: IntellisenseSuggestion[], clickhouseTables: { name: string; columns: string[]; }[]) {
  suggestions = suggestions.concat(
    clickhouseTables.map(tbl => ({
      label: tbl.name,
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: tbl.name,
      documentation: '',
      sortText: 'b' + tbl.name,
    }))
  );
  return suggestions;
}

function addChColumns(suggestions: IntellisenseSuggestion[], clickhouseTables: { name: string; columns: string[]; }[]) {
  suggestions = suggestions.concat(clickhouseTables.flatMap(tbl => tbl.columns.map(col => {
    return {
      label: `${col} (in ${tbl.name})`,
      kind: monaco.languages.CompletionItemKind.Field,
      insertText: col,
      sortText: 'c' + col,
      documentation: ''
    };
  })));
  return suggestions;
}

function addRegularFunctions(suggestions: IntellisenseSuggestion[]) {
  suggestions = suggestions.concat(
    clickHouseFunctions.regularFunctions.map(func => ({
      label: func.label,
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: func.label,
      documentation: func.documentation,
      sortText: 'd' + func.label,
    })));
  return suggestions;
}

function addAggregateFunctions(suggestions: IntellisenseSuggestion[]) {
  suggestions = suggestions.concat(
    clickHouseFunctions.aggregateFunctions.map(func => ({
      label: func.label,
      kind: monaco.languages.CompletionItemKind.Function,
      insertText: func.label,
      sortText: 'd' + func.label,
      documentation: func.documentation
    })));
  return suggestions;
}

function getNextOperator(lastKeyword: string): string[] {

  const transitions = {
    '': ['SELECT', 'WITH'],
    'WITH': ['SELECT'],
    'SELECT': ['FROM'],
    'FROM': ['INNER JOIN', 'LEFT JOIN', 'RIGHT JOIN', 'WHERE', 'GROUP BY', 'ORDER BY', 'LIMIT'],

    // Joins typically occur after FROM or another JOIN, and you can continue to WHERE, GROUP BY, etc.
    'JOIN': ['ON'],
    'ON': ['INNER JOIN', 'LEFT JOIN', 'RIGHT JOIN', 'WHERE', 'GROUP BY', 'ORDER BY', 'LIMIT'],

    'WHERE': ['GROUP BY', 'ORDER BY', 'LIMIT', ...getFullVisualizationExpressions()],
    'GROUP BY': ['ORDER BY', 'LIMIT', ...getFullVisualizationExpressions()],
    'ORDER BY': ['ASC', 'DESC', 'LIMIT', ...getFullVisualizationExpressions()],
    'ASC': ['LIMIT', ...getFullVisualizationExpressions()],
    'DESC': ['LIMIT', ...getFullVisualizationExpressions()],
    'LIMIT': [...getFullVisualizationExpressions()],
    'VISUALIZE': getVisualizationKeywords(),
  };

  // Convert the input to the normalized form (e.g., uppercase)
  const normalized = (lastKeyword || '').toUpperCase();

  // Return the possible next keywords if available, or an empty array otherwise
  return transitions[normalized] || [];
}

function getFullVisualizationExpressions() {
  return getVisualizationKeywords().map(v => `VISUALIZE ${v}`);
}


function getVisualizationKeywords() {
  return vis.map(v => v.intellisense.toUpperCase());
}

function getWithAliases(words): string[] {
  const withAliases = [];
  for (let i = 0; i < words.length - 2; i++) {
    if (words[i].toUpperCase() === 'WITH' && words[i + 2].toUpperCase() === 'AS') {
      withAliases.push(words[i + 1]);
    }
  }
  return withAliases;
}