import './challenges.css';
import ChallengeSolution from './challenge-solution';

const Challenge1 = (() => {

  return (
    <div className="challenge-inner-container">
      <p>The first order of business is to get closer to the problems. What kind of errors are there in the application?
      Try listing some 100 logs with the level 5 (Error).</p>
      <br/><p>You can either write the queries yourself or use the AI assistant.</p>
      <br/>
      <ChallengeSolution solutionQuery="SELECT * FROM Logs 
WHERE severity = 'ERROR'
LIMIT 100"/>
      <p>After seeing the table schema, try counting the amount of errors for each distinct 'message'.</p>
      <ChallengeSolution solutionQuery="SELECT COUNT(*), message FROM Logs
WHERE level = 5
GROUP BY message"/>
      
    </div>
  );
});

export default Challenge1;
