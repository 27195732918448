import React from 'react';
import { useEffect, useState } from 'react';
import Login from './login2/login';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebase';
import './common.css';
import QueryTabs from './query/query-tabs';
import logger from './utils/logger';
import Sidebar from './sidebar/sidebar';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { isSandboxUser } from './utils/url-utils';
import Challenges from './query/sandbox/challenges';
import TopBar from './top-bar';
import GoodAnswerModal from './components/good-answer-modal';

function App() {
  const notLoggedIn = 'not-logged-in' as const;
  const [user, setUser] = useState<User | typeof notLoggedIn | null>(null); // null means still loading
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [goodAnswerModalProps, setGoodAnswerModalProps] = useState({ prompt: '', answer: '' });

  
  useEffect(() => {
    const authStateChangedCleanup = onAuthStateChanged(auth, (user) => {
      if (user) { // User is logged in
        setUser(user);

        logger.info(`User logged in. email: ${user.email} uid: ${user.uid} `, "KAj4m");
        logger.flush();
      } else { // User is logged out
        setUser(notLoggedIn);
        logger.info(`onAuthStateChanged: User is logged out`, "VS5D5");

      }
    });

    return () => authStateChangedCleanup();
  }, []);

  function openGoodAnswerModal(prompt: string, answer: string): void {
    setGoodAnswerModalProps({ prompt, answer });
    setIsModalOpen(true);
  }

  return (
    <div id="app-container">
      <Router>
        {user !== null && user !== notLoggedIn ? (
          <div className='flex flex-row '>
            <Sidebar />
            <div className="bg-[#FF0000] flex-1 flex flex-col">
              <TopBar user={user} />
              <div id="main-content"
                className='bg-[#F4F2F0] flex-1  flex flex-row '>

                <Routes>
                  <Route path="/" element={<Navigate to="/query" replace />} />
                  <Route path="/query" element={<QueryTabs user={user} openGoodAnswerModal={openGoodAnswerModal} />} />
                  <Route path="/dashboards" element={<div>dashboards</div>} />
                </Routes>
                {isSandboxUser(user) &&
                  <Challenges />
                }

              </div>

            </div>

          </div>
        ) : (
          // user logged out, showing login page
          <div>
            {user === notLoggedIn && <Login />}
            {user === null && <div>Loading...</div>}
          </div>
        )}
      </Router>


      {/* Modal Code */}
      {(isModalOpen && user != null && user !== notLoggedIn) && <GoodAnswerModal user={user} closeModal={()=> setIsModalOpen(false)} goodAnswerModalProps={goodAnswerModalProps} />}
    </div>
  );
}

export default App;
