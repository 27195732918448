import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { ColDef } from 'ag-grid-community';
import logger from '../../utils/logger';
import { HiZoomIn } from "react-icons/hi";




function MagnifierCellRenderer(onInspectCell: (cellValue: string, force: boolean) => void, props) {
    const handleIconClick = useCallback(
        (event) => {
            event.stopPropagation();
            onInspectCell(props.value, true); // props.data contains row
        },
        [props]
    );

    return (
        <div className="table-cell-renderer group relative flex items-center px-3 min-w-[400px]">
            <span className='z-1 text-ellipsis'>{typeof props.value == 'object' ? JSON.stringify(props.value) : props.value}</span>
            <span
                className="ml-2 mb-[2px] hidden group-hover:inline-block cursor-pointer text-blue-600 absolute right-0 p-1 z-2 bg-white bg-opacity-80 border border-gray-200 rounded-lg"
                onClick={handleIconClick}
            >
                <HiZoomIn size={18} color='#777' />
                {/* color='#3C4B9C' */}
            </span>
        </div>
    );
}


interface TableProps {
    visible: boolean;
    onInspectCell: (cellValue: string, force: boolean) => void;
}

const Table = forwardRef((props: TableProps, ref) => {

    const tableRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateTable,
        callCheckMouseInside: (event) => tableRef.current && tableRef.current.contains(event.target),
    }));

    const updateTable = (results) => {
        logger.verbose(`table updateTable results.length=${results?.length}`, "Yqi7j");

        if (results.length === 0) {
            //[{field: "result"}];
            setRowData([]);
        } else {
            if (!Array.isArray(results)) {
                results = [results];
            }

            const firstRow = results[0];
            const cols = Object.keys(firstRow).map((key) => ({ 
                field: key, 
                minWidth: key == "severity" ? 80 
                    : key == "trace_flags" ? 80
                    : key == "timestamp" ? 142
                    : key == "message" ? 350
                    : undefined,
                headerName: key, 
                cellRenderer: (props1) => MagnifierCellRenderer(props.onInspectCell, props1) 
            }));
            setColDefs(cols);
            setRowData(results);
        }
    }

    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState<ColDef[]>([]);

    const defaultColDef: ColDef = {
        flex: 1,
        filter: true,
        resizable: true,
        minWidth: 150,
        cellStyle: { paddingLeft: "0", paddingRight: "0" },
    };

    let lastRow = undefined;
    let lastColId = undefined;
    let lastValue = undefined;

    const onSelectionChanged = (ev) => {
        try {
            const selectedRows = ev?.api?.getSelectedRows();
            if (!selectedRows || selectedRows.length === 0) {
                return;
            }
            lastRow = selectedRows[0];
            lastValue = lastRow[lastColId];
        } catch (error) {
            logger.error("onSelectionChanged error: " + error, "vADb8");
        }
    }

    const onCellClicked = (ev) => {
        console.log("onCellClicked", ev.value);
    }

    const onCellFocused = (ev) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            lastColId = (ev.column as any).colId;
            const rowIndex: number = ev.rowIndex;
            const row = rowData[rowIndex];
            lastValue = row[lastColId];
            if (lastValue) {
                props.onInspectCell(lastValue, false /* force */);
            }

        } catch (error) {
            logger.error("onCellFocused error: " + error, "vADb7");
        }
    }




    const onCellKeyDown = (event) => {
        // Copy if Ctrl (or Command) and 'C' are pressed
        const isCtrlOrCmdPressed = event.event.ctrlKey || event.event.metaKey; // MetaKey is used for Mac (Cmd)
        const isCPressed = event.event.key === 'c' || event.event.key === 'C';
        const isEnterPressed = event.event.key === 'Enter';
        if (isCtrlOrCmdPressed && isCPressed) {
            const selectedText = window.getSelection().toString();
            if (!selectedText) {
                const text = Object.values(lastRow).join(' ');
                navigator.clipboard.writeText(text);
            }
        } else if (isEnterPressed) {
            props.onInspectCell(lastValue, true);
        }

    }


    return (
        <div ref={tableRef}
            className="ag-theme-quartz results-view" // applying the Data Grid theme
            style={{
                height: '100%',
                border: 'none',
                // height: props.height.toString() + '%',
                visibility: props.visible ? 'visible' : 'hidden',
                display: props.visible ? 'block' : 'none'
            }} // the Data Grid will fill the size of the parent container
        >
            <AgGridReact
                pagination={rowData.length > 100}
                paginationPageSizeSelector={[10, 20, 50, 100, 200, 500, 1000]}
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                rowHeight={30}
                headerHeight={35}
                preventDefaultOnContextMenu={true}
                copyHeadersToClipboard={true}
                rowSelection={{ mode: 'singleRow', enableClickSelection: true, checkboxes: false }}
                onSelectionChanged={onSelectionChanged}
                onCellKeyDown={onCellKeyDown}
                onCellClicked={onCellClicked}
                onCellDoubleClicked={(ev) => props.onInspectCell(ev.value, true)}

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onCellFocused={onCellFocused}
            />
        </div>
    );
});

export default Table;